// import React, { useRef, useState } from "react";
// import videojs from "video.js";
// import "video.js/dist/video-js.css";
// import dynamicWatermark from "videojs-dynamic-watermark";

// const VideoCryptPlayer = ({ src, userData = {} }) => {
//   const videoPlayerRef = useRef(null);
//   const videoUrl = String(src);
//   const [currentTime, setCurrentTime] = useState();

//   const videoJSOptions = {
//     type: "application/x-mpegURL",
//     autoplay: "muted",
//     controls: true,
//     userActions: { hotkeys: true },
//   };

//   React.useEffect(() => {
//     let player = null; // Declare the player variable

//     if (videoPlayerRef.current && src) {
//       videojs.registerPlugin("dynamicWatermark", dynamicWatermark);

//       player = videojs(videoPlayerRef.current, videoJSOptions, () => {
//         player.src({
//           src: videoUrl,
//           type: videoJSOptions.type,
//         });

//         player.dynamicWatermark({
//           // elementId: "vjs_video_3_html5_api",
//           watermarkText: userData.ip,
//           changeDuration: 20000,
//           cssText:
//             "display: inline-block; color: red; background-color: transparent; font-size: 0.8rem; z-index: 98; position: absolute; @media only screen and (max-width: 992px){font-size: 0.8rem;}",
//         });
//         player.dynamicWatermark({
//           // elementId: "vjs_video_3_html5_api",
//           watermarkText: userData.name,
//           changeDuration: 20000,
//           cssText:
//             "display: inline-block; color: red; background-color: transparent; font-size: 0.8rem; z-index: 98; position: absolute; @media only screen and (max-width: 992px){font-size: 0.8rem;}",
//         });
//         player.dynamicWatermark({
//           // elementId: "vjs_video_3_html5_api",
//           watermarkText: userData.email,
//           changeDuration: 20000,
//           cssText:
//             "display: inline-block; color: red; background-color: transparent; font-size: 0.8rem; z-index: 98; position: absolute; @media only screen and (max-width: 992px){font-size: 0.8rem;}",
//         });
//         player.dynamicWatermark({
//           // elementId: "vjs_video_3_html5_api",
//           watermarkText: userData.mobile,
//           changeDuration: 20000,
//           cssText:
//             "display: inline-block; color: red; background-color: transparent; font-size: 0.8rem; z-index: 98; position: absolute; @media only screen and (max-width: 992px){font-size: 0.8rem;}",
//         });

//         player.on("ended", () => {});
//         player.on("timeupdate", () => {
//           setCurrentTime(player.currentTime());
//         });
//       });
//     }
//     return () => {
//       if (player) {
//         player.dispose();
//       }
//     };
//     //  eslint-disable-next-line
//   }, [src]);

//   return (
//     <div className="jw-video-container" id={"video-crypt-player"}>
//       {src && (
//         <video
//           style={{ width: "100%", height: "100%" }}
//           ref={videoPlayerRef}
//           className="video-js"
//         />
//       )}
//     </div>
//   );
// };

// export default VideoCryptPlayer;

import React, { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import _ from "videojs-contrib-quality-levels";
import qualitySelector from "videojs-hls-quality-selector";
import dynamicWatermark from "videojs-dynamic-watermark";
import "video.js/dist/video-js.css";
import { LiveLectureImg2 } from "assets/images";

const VideoCryptPlayer = ({ src, userData = {} }) => {
  const videoRef = useRef();
  const [player, setPlayer] = useState(undefined);
  console.log("videoRef",videoRef);

  const liveURL = src;
  useEffect(() => {
    if (player) {
      player.src({
        src: liveURL,
        type: "application/x-mpegURL",
        withCredentials: false,
      });

      player.poster("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveURL]);

  useEffect(() => {
    const videoJsOptions = {
      autoplay: false,
      // liveui: true,
      preload: "auto",
      controls: true,
      poster: LiveLectureImg2,
      sources: [
        {
          src: liveURL,
          type: "application/x-mpegURL",
          withCredentials: false,
        },
      ],
      html5: {
        nativeAudioTracks: true,
        nativeVideoTracks: true,
        nativeTextTracks: true,
      },
    };
    videojs.registerPlugin("dynamicWatermark", dynamicWatermark);

    const p = videojs(
      videoRef.current,
      videoJsOptions,
      function onPlayerReady() {}
    );

    p.dynamicWatermark({
      elementId: "ip",
      watermarkText: userData.ip,
      changeDuration: 20000,
      cssText:
        "display: inline-block; color: red; background-color: transparent; font-size: 0.8rem; z-index: 98; position: absolute; @media only screen and (max-width: 992px){font-size: 0.8rem;}",
    });
    p.dynamicWatermark({
      elementId: "name",
      watermarkText: userData.name,
      changeDuration: 20000,
      cssText:
        "display: inline-block; color: red; background-color: transparent; font-size: 0.8rem; z-index: 98; position: absolute; @media only screen and (max-width: 992px){font-size: 0.8rem;}",
    });
    p.dynamicWatermark({
      elementId: "email",
      watermarkText: userData.email,
      changeDuration: 20000,
      cssText:
        "display: inline-block; color: red; background-color: transparent; font-size: 0.8rem; z-index: 98; position: absolute; @media only screen and (max-width: 992px){font-size: 0.8rem;}",
    });
    p.dynamicWatermark({
      elementId: "mobile",
      watermarkText: userData.mobile,
      changeDuration: 20000,
      cssText:
        "display: inline-block; color: red; background-color: transparent; font-size: 0.8rem; z-index: 98; position: absolute; @media only screen and (max-width: 992px){font-size: 0.8rem;}",
    });

    setPlayer(p);

    return () => {
      if (player) player.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (player) {
      player.hlsQualitySelector({ displayCurrentQuality: true });
    }
  }, [player]);
  return (
    <>
      <div data-vjs-player>
        <video
          style={{ width: "100%", height: "100%" }}
          ref={videoRef}
          className="vidPlayer video-js vjs-default-skin vjs-big-play-centered"
        ></video>
      </div>
    </>
  );
};

export default VideoCryptPlayer;
