import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { BsFillPlayFill } from "react-icons/bs";
import { useNavigate, useLocation } from "react-router-dom";
import ListCourseSkeleton from "components/skeleton/ListCourseSkeleton";
import LectureNotFound from "components/Not-Found/LectureNotFound";
import {
  countArray,
  momentDateFormat,
  timeFormatWith12Hour,
  lectureAccess,
  lectureIsStarted,
  lectureIsExpired,
} from "config/utils";
import { lectureListView } from "services/lecture";
import Pagination from "react-js-pagination";

import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useCourse } from "context/context";
import { AiOutlineDownload, AiOutlineSearch } from "react-icons/ai";
import { errorResponse, failResponse, normalEncryptData } from "config/config";
import useDebounce from "hooks/useDebounce";
import { getSignedUrl } from "services/master";
const ListLectures = () => {
  const { course } = useCourse();
  const [loading, setLoading] = useState(false);
  const [listLectures, setListLectures] = useState([]);
  const [total, setTotal] = useState(0);
  const [activePage, setActivePage] = useState(1);

  const [isNext, setIsNext] = useState(false);
  const [isPrev, setIsPrev] = useState(false);
  const [nextId, setNextId] = useState("");
  const [search, setSearch] = useState("");
  const debounceValue = useDebounce(search, 300);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  const paths = state?.path;
  console.log("pppppppp", paths);

  const [submitLoading, setSubmitLoading] = useState(false);

  // get course lectures
  // const getLecturesList = async (
  //   type = "",
  //   courseId = course.courseId,
  //   search = debounceValue,
  //   page = activePage
  // ) => {
  //   try {
  //     setLoading(true);
  //     const data = {};

  //     if (type === "next") {
  //       if (listLectures.length > 0) {
  //         data.nextLecture = listLectures[listLectures.length - 1].id;
  //         localStorage.setItem(
  //           "ldid",
  //           listLectures[listLectures.length - 1].id
  //         );
  //       } else {
  //         data.nextLecture = nextId;
  //       }
  //       console.log(listLectures[listLectures.length - 1]);
  //     }

  //     if (type === "prev") {
  //       if (listLectures.length > 0) {
  //         data.previousLecture = listLectures[0].id;
  //         localStorage.setItem("ldid", listLectures[0].id);
  //       } else {
  //         data.previousLecture = nextId;
  //         data.currentLectureAvailable = false;
  //       }
  //     }

  //     if (type == "") {
  //       localStorage.removeItem("ldid");
  //       localStorage.removeItem("lectdetail");
  //       localStorage.removeItem("ctype");
  //       localStorage.removeItem("ldcnt");
  //     }

  //     if (type === "ldprev") {
  //       const cnt = localStorage.getItem("ldcnt");
  //       if (cnt) {
  //         localStorage.setItem("ldcnt", 2);
  //       } else {
  //         localStorage.setItem("ldcnt", 1);
  //       }

  //       data.previousLecture = localStorage.getItem("ldid");
  //       if (cnt == 2) {
  //         localStorage.removeItem("ldid");
  //         localStorage.removeItem("lectdetail");
  //         localStorage.removeItem("ctype");
  //         localStorage.removeItem("ldcnt");
  //       }
  //     }

  //     if (type === "ldnext") {
  //       const cnt = localStorage.getItem("ldcnt");
  //       if (cnt) {
  //         localStorage.setItem("ldcnt", 2);
  //       } else {
  //         localStorage.setItem("ldcnt", 1);
  //       }

  //       data.nextLecture = localStorage.getItem("ldid");
  //       if (cnt == 2) {
  //         localStorage.removeItem("ldid");
  //         localStorage.removeItem("lectdetail");
  //         localStorage.removeItem("ctype");
  //         localStorage.removeItem("ldcnt");
  //       }
  //     }

  //     const res = await lectureListView({
  //       course_id: courseId,
  //       search,
  //       page,
  //       ...data,
  //     });

  //     if (res.code === 200) {
  //       setListLectures(res.data.lectures.rows || []);
  //       setTotal(res.data.lectures.count || []);
  //       setNextId(res.data.nextCourseId);
  //       setLoading(false);
  //       localStorage.removeItem("lectdetail");
  //     } else {
  //       failResponse(res);
  //       setLoading(false);
  //     }
  //   } catch (err) {
  //     errorResponse(err);
  //     setLoading(false);
  //   }
  // };

  React.useEffect(() => {
    if (course.courseId) {
      setActivePage(1);  // Reset page to 1 when courseId changes
    }
  }, [course.courseId]);
  


  const getLecturesList = async (
    type = "",
    courseId = course.courseId,
    search = debounceValue,
    page = activePage  // Default to activePage, which will be 1 after courseId change
  ) => {
    try {
      setLoading(true);
      const data = {};
  
      // Handle 'next' type for lectures list
      if (type === "next") {
        if (listLectures.length > 0) {
          data.nextLecture = listLectures[listLectures.length - 1].id;
          localStorage.setItem(
            "ldid",
            listLectures[listLectures.length - 1].id
          );
        } else {
          data.nextLecture = nextId;
        }
      }
  
      // Handle 'prev' type for lectures list
      if (type === "prev") {
        if (listLectures.length > 0) {
          data.previousLecture = listLectures[0].id;
          localStorage.setItem("ldid", listLectures[0].id);
        } else {
          data.previousLecture = nextId;
          data.currentLectureAvailable = false;
        }
      }
  
      // Reset localStorage if no type is provided
      if (type == "") {
        localStorage.removeItem("ldid");
        localStorage.removeItem("lectdetail");
        localStorage.removeItem("ctype");
        localStorage.removeItem("ldcnt");
      }
  
      // Handle 'ldprev' type for lectures list
      if (type === "ldprev") {
        const cnt = localStorage.getItem("ldcnt");
        if (cnt) {
          localStorage.setItem("ldcnt", 2);
        } else {
          localStorage.setItem("ldcnt", 1);
        }
  
        data.previousLecture = localStorage.getItem("ldid");
        if (cnt == 2) {
          localStorage.removeItem("ldid");
          localStorage.removeItem("lectdetail");
          localStorage.removeItem("ctype");
          localStorage.removeItem("ldcnt");
        }
      }
  
      // Handle 'ldnext' type for lectures list
      if (type === "ldnext") {
        const cnt = localStorage.getItem("ldcnt");
        if (cnt) {
          localStorage.setItem("ldcnt", 2);
        } else {
          localStorage.setItem("ldcnt", 1);
        }
  
        data.nextLecture = localStorage.getItem("ldid");
        if (cnt == 2) {
          localStorage.removeItem("ldid");
          localStorage.removeItem("lectdetail");
          localStorage.removeItem("ctype");
          localStorage.removeItem("ldcnt");
        }
      }
  
      // Make the API call to fetch lectures list
      const res = await lectureListView({
        course_id: courseId,
        search,
        page,  // Use the current value of activePage, which defaults to 1 after courseId change
        ...data,
      });
  
      if (res.code === 200) {
        setListLectures(res.data.lectures.rows || []);
        setTotal(res.data.lectures.count || []);
        setNextId(res.data.nextCourseId);
        setLoading(false);
        localStorage.removeItem("lectdetail");
      } else {
        failResponse(res);
        setLoading(false);
      }
    } catch (err) {
      errorResponse(err);
      setLoading(false);
    }
  };
  
  console.log(total);

  // next handler
  const nextHandler = async () => {
    getLecturesList("next");
    localStorage.setItem("ctype", "ldnext");
  };

  // previous handler
  const prevHandler = async () => {
    localStorage.setItem("ctype", "ldprev");
    getLecturesList("prev");
  };

  // navigation handler
  const navigationHandler = (id, startDate, endDate, time) => {
    if (!course.coursePlatformEnabled) {
      toast.error(
        "Lectures of Tablet platform or Mobile platform cannot be seen on website "
      );
      return;
    }

    if (lectureIsStarted(startDate, time)) {
      toast.error("Lecture is not started");
      return;
    }
    if (lectureIsExpired(endDate, time)) {
      toast.error("Lecture is expired");
      return;
    }
    if (lectureAccess(startDate, endDate, time)) {
      navigate(`/dashboard/lecture/detail/${normalEncryptData(id, "route")}`, {
        state: {
          auth: true,
          location: `/dashboard/lecture/${id}`, // Store the complete detail page URL
          previousLectureId: id,
        },
      });
    }
  };

  // notes download handler
  // const notesDownloadHandler = async (notes, startDate, endDate, time) => {
  //   if (lectureAccess(startDate, endDate, time)) {
  //     setSubmitLoading(true);
  //     window.open(await getSignedUrl({ pdfUrl: notes }));
  //     setSubmitLoading(false);
  //   } else {
  //     toast.error("you can't download notes");
  //   }
  // };

  const notesDownloadHandler = async (notes) => {
    setSubmitLoading(true);
    window.open(await getSignedUrl({ pdfUrl: notes }));
    setSubmitLoading(false);
  };

  // ----------------------- getting courses detail data -------------------------//
  React.useEffect(() => {
    const ctype = localStorage.getItem("ctype");
    const lectdetail = localStorage.getItem("lectdetail");
    const ldid = localStorage.getItem("ldid");
    //alert(ctype); //alert(lectdetail); alert(ldid);
    if (lectdetail) {
      localStorage.setItem("ldcnt", 1);
      getLecturesList(ctype);
    } else {
      if (course.courseId) {
        getLecturesList();
      }
    }

    // eslint-disable-next-line
  }, [course, debounceValue, activePage]);

  return (
    <>
      <div className="pagination_search_class">
        <div className="d-flex flex-column flex-md-row justify-content-between py-2 align-items-center">
          {/* Pagination on the top for mobile and left side for desktop */}
          <div className="d-flex mb-2 mb-md-0">
            {total > 10 ? (
              <div className="pagination_wrap">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  activeLinkClass={"active"}
                  onChange={(page) => setActivePage(page)}
                />
              </div>
            ) : null}
          </div>

          {/* Search bar on the bottom for mobile and right side for desktop */}
          <div className="search_bar_wrap d-flex" style={{ width: "275px" }}>
            <div className="search_bar">
              <input
                type="search"
                placeholder="Search"
                className="input"
                value={search}
                onChange={({ target }) => setSearch(target.value)}
              />
              <AiOutlineSearch />
            </div>
          </div>
        </div>
      </div>

      <div className="daily_lecture">
        <div className="meeting_content">
          <ul>
            {/* ************ Loading Component *********** */}
            {loading && (
              <>
                {countArray(8).map((data) => {
                  return (
                    <li key={data} className="p-0">
                      <ListCourseSkeleton height={90} />
                    </li>
                  );
                })}
              </>
            )}
            {/* iterate lectures */}
            <li className="lecture-card heading">
              <h6>Lecture Name</h6>
              <div className="play_btn">
                <b>Video</b>
              </div>
              <div className="date">
                <b>Start Date</b>
              </div>
              <div className="date">
                <b>End Date</b>
              </div>
              <div className="date">
                <b>Teacher</b>
              </div>
              <div className="date">
                <b>Duration</b>
              </div>
              <div className="date">
                <b>Class Notes</b>
              </div>
            </li>
            {!loading &&
              listLectures.length > 0 &&
              listLectures.map(
                ({
                  LectureID,
                  LectureTitle,
                  Duration,
                  Notes,
                  startDate,
                  endDate,
                  lecturetime,
                  TeacherName,
                }) => {
                  return (
                    <li
                      key={LectureID}
                      // className={`lecture-card ${
                      //   !lectureAccess(startDate, endDate, lecturetime) && "disabled"
                      // }`}
                      className="lecture-card"
                    >
                      {/* <Link
                        to={`/dashboard/lecture/detail/${LectureID}`}
                        className={`image ${IsYoutube ? "has_youtube" : ""}`}
                      >
                        {!IsYoutube ? (
                          <img
                            src={
                              IsYoutube ? IsYoutube : vdocipherDetail?.poster
                            }
                            alt=""
                          />
                        ) : (
                          ""
                        )}
                        {IsYoutube ? (
                          <span className="icon">
                            <BsYoutube />
                          </span>
                        ) : (
                          <BsPlayCircleFill />
                        )}
                      </Link> */}
                      {lectureAccess(startDate, endDate, lecturetime) ? (
                        <>
                          <h6
                            onClick={() =>
                              navigationHandler(
                                LectureID,
                                startDate,
                                endDate,
                                lecturetime
                              )
                            }
                          >
                            {LectureTitle || "N/A"}
                          </h6>
                          <div className="play_btn">
                            <span
                              onClick={() =>
                                navigationHandler(
                                  LectureID,
                                  startDate,
                                  endDate,
                                  lecturetime
                                )
                              }
                            >
                              <span className="play-icon">
                                <BsFillPlayFill />
                              </span>
                            </span>
                          </div>
                          <div className="date">
                            {/* <span>Start Date</span> */}
                            <b>{`${momentDateFormat(startDate)} `}</b>
                            <br />
                            <b>{`${timeFormatWith12Hour(lecturetime)}`}</b>
                          </div>
                          <div className="date red">
                            {/* <span>End Date</span> */}

                            <b>{`${momentDateFormat(endDate)}`}</b>
                            <br />
                            <b>{`${timeFormatWith12Hour(lecturetime)}`}</b>
                          </div>
                          <div className="date">
                            {/* <span>Educator</span> */}
                            <b>{TeacherName || "N/A"}</b>
                          </div>
                          <div className="date">
                            {/* <span>Duration</span> */}
                            <b>{Duration} mins</b>
                          </div>

                          <div className="date">
                            {/* <span>Class Notes</span> */}
                            {Notes ? (
                              <button
                                className="dashboard_button"
                                onClick={() =>
                                  notesDownloadHandler(
                                    Notes
                                    // startDate,
                                    // endDate,
                                    // lecturetime
                                  )
                                }

                                // disabled={
                                //   !lectureAccess(startDate, endDate, lecturetime) ||
                                //   !Notes ||
                                //   submitLoading
                                // }
                              >
                                <AiOutlineDownload /> Download
                              </button>
                            ) : (
                              <b>N/A</b>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <h6
                            style={
                              lectureAccess(startDate, endDate, lecturetime)
                                ? {}
                                : { color: "darkgray" }
                            }
                            onClick={() =>
                              navigationHandler(
                                LectureID,
                                startDate,
                                endDate,
                                lecturetime
                              )
                            }
                          >
                            {LectureTitle || "N/A"}
                          </h6>
                          <div className="play_btn disabled">
                            <span
                              onClick={() =>
                                navigationHandler(
                                  LectureID,
                                  startDate,
                                  endDate,
                                  lecturetime
                                )
                              }
                            >
                              <span className="play-icon disabled">
                                <BsFillPlayFill />
                              </span>
                            </span>
                          </div>
                          <div className="date disabled">
                            {/* <span>Start Date</span> */}
                            <b>{`${momentDateFormat(startDate)} `}</b>
                            <br />
                            <b>{`${timeFormatWith12Hour(lecturetime)}`}</b>
                          </div>
                          <div className="date disabled">
                            {/* <span>End Date</span> */}

                            <b>{`${momentDateFormat(endDate)}`}</b>
                            <br />
                            <b>{`${timeFormatWith12Hour(lecturetime)}`}</b>
                          </div>
                          <div className="date disabled">
                            {/* <span>Educator</span> */}
                            <b>{TeacherName || "N/A"}</b>
                          </div>
                          <div className="date disabled">
                            {/* <span>Duration</span> */}
                            <b>{Duration} mins</b>
                          </div>

                          <div className="date">
                            {/* <span>Class Notes</span> */}
                            {Notes ? (
                              <button
                                className="dashboard_button"
                                onClick={() =>
                                  notesDownloadHandler(
                                    Notes
                                    // startDate,
                                    // endDate,
                                    // lecturetime
                                  )
                                }

                                // disabled={
                                //   !lectureAccess(startDate, endDate, lecturetime) ||
                                //   !Notes ||
                                //   submitLoading
                                // }
                              >
                                <AiOutlineDownload /> Download
                              </button>
                            ) : (
                              <b>N/A</b>
                            )}
                          </div>
                        </>
                      )}
                    </li>
                  );
                }
              )}

            {/* Not Found */}
            {!loading && listLectures.length <= 0 && (
              <>
                <LectureNotFound title="Lectures" />
              </>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ListLectures;
