import React, { useRef, useState } from "react";
import { Logo, OBJECTSimg } from "assets/images";
import { FaRegClock } from "react-icons/fa";
import "./TestUnit.scss";
import {
  Navigate,
  useLocation,
  useNavigate,
  useNavigationType,
  useParams,
} from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useCourse } from "context/context";
import {
  userAttemptMCQTestSeries,
  userTestSeriesDetail,
  userTestSeriesQuestions,
} from "services/test";
import { toast } from "react-hot-toast";
import { minutesConversion, secondsConversion } from "config/utils";
import QuestionsComponent from "./sections/Questions";
import ReviewComponent from "./sections/Review";
import TestUnitSkeleton from "components/skeleton/TestUnitSkeleton";
import useButtonLoader from "hooks/useButtonLoader";
import { decryptData, errorResponse, failResponse } from "config/config";
import Countdown from "react-countdown";
import { zeroPad } from "react-countdown";

const TestUnit = () => {
  const[Duration, setDuration] = useState(100000);
  const renderer = ({ hours, minutes, seconds }) => {
    return <span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
  };
  
  const clientTime = Date.now();
  const { course, setCourse } = useCourse();
  const { testId } = useParams();
  const [loading, setLoading] = useState(false);
  const timerRef = useRef(null);
  const userStayRef = useRef(0);

  const countRef = useRef(null);

  const [buttonRef, setButtonLoading] = useButtonLoader("Save & Next");
  const navigate = useNavigate();
  const location = useLocation();
  const navType = useNavigationType();

  // flag for previous data
  const [flag, setFlag] = useState(true);
  const [expiry, setExpiry] = useState(false);

  const [allQuestionsData, setAllQuestionsData] = useState([]);
  const [, setTestDetails] = useState({});

  const [allTestQuestions, setAllTestQuestions] = useState([]);
  const [totalOptions, setTotalOptions] = useState(0);
  const [allLanguages, setAllLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState("");

  const [currentPosition, setCurrentPosition] = useState(1);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [selectedOption, setSelectedOption] = useState({
    questionId: "",
    qanswer: "",
    qoption: "",
  });

  const [show, setShow] = useState(false);

  //   test next question function
  const nextQuestionHandler = () => {
    if (!selectedOption.qanswer) toast.error("Please select option");
    else {
      // checks data is already inserted or not
      if (
        selectedAnswers
          .map((data) => data?.questionId)
          .includes(selectedOption?.questionId)
      ) {
        let cloneAnswers = JSON.parse(JSON.stringify(selectedAnswers));
        const findIndex = selectedAnswers.findIndex(
          (data) => data?.questionId === selectedOption.questionId
        );
        //   update the existing value
        cloneAnswers[findIndex] = { ...selectedOption, qoption: "" };

        setSelectedAnswers(cloneAnswers);
        setFlag(!flag);
      } else {
        let answerClone = JSON.parse(JSON.stringify(selectedAnswers));
        answerClone[currentPosition - 1] = {
          ...selectedOption,
          qoption: "",
        };
        setSelectedAnswers(answerClone);
        setFlag(!flag);
      }
    }

    // switch to next test
    if (currentPosition !== allTestQuestions.length) {
      setCurrentPosition((currentPosition) => currentPosition + 1);
      setSelectedOption({
        questionId: "",
        qanswer: "",
      });
    }
    // final submit modal
    if (currentPosition === allTestQuestions.length) {
      setShow(true);
    }
  };

  //   test review question function
  const reviewQuestionHandler = () => {
    if (!selectedOption.qanswer) toast.error("Please select option");
    else {
      if (
        selectedAnswers
          .map((data) => data?.questionId)
          .includes(selectedOption?.questionId)
      ) {
        let cloneAnswers = JSON.parse(JSON.stringify(selectedAnswers));
        const findIndex = selectedAnswers.findIndex(
          (data) => data?.questionId === selectedOption.questionId
        );
        //   update the existing value
        cloneAnswers[findIndex] = { ...selectedOption, qoption: "Review" };

        setSelectedAnswers(cloneAnswers);
        setFlag(!flag);
      } else {
        let answerClone = JSON.parse(JSON.stringify(selectedAnswers));
        answerClone[currentPosition - 1] = {
          ...selectedOption,
          qoption: "Review",
        };
        setSelectedAnswers(answerClone);
        setFlag(!flag);
      }
    }

    // switch to next test
    if (currentPosition !== allTestQuestions.length) {
      setCurrentPosition((currentPosition) => currentPosition + 1);
      setSelectedOption({
        questionId: "",
        qanswer: "",
      });
    }
    // final submit modal
    if (currentPosition === allTestQuestions.length) {
      setShow(true);
    }
  };

  //   test skip handler
  const skipQuestionHandler = () => {
    // if option is selected
    if (selectedOption?.qanswer) {
      // checks data is already inserted or not
      if (
        selectedAnswers
          .map((data) => data?.questionId)
          .includes(selectedOption?.questionId)
      ) {
        let cloneAnswers = JSON.parse(JSON.stringify(selectedAnswers));
        const findIndex = selectedAnswers.findIndex(
          (data) => data?.questionId === selectedOption.questionId
        );
        //   update the existing value
        cloneAnswers[findIndex] = { ...selectedOption, qoption: "SKIP" };

        setSelectedAnswers(cloneAnswers);
        setFlag(!flag);
      } else {
        let position = currentPosition - 1;
        let answerClone = JSON.parse(JSON.stringify(selectedAnswers));
        answerClone[position] = { ...selectedOption, qoption: "SKIP" };
        setSelectedAnswers(answerClone);
        setFlag(!flag);
      }
    }

    // if option is not selected
    if (!selectedOption?.qanswer) {
      // checks data is already inserted or not
      if (
        selectedAnswers
          .map((data) => data?.questionId)
          .includes(selectedOption?.questionId)
      ) {
        let cloneAnswers = JSON.parse(JSON.stringify(selectedAnswers));
        const findIndex = selectedAnswers.findIndex(
          (data) => data?.questionId === selectedOption?.questionId
        );
        //   update the existing value
        let position = currentPosition - 1;

        cloneAnswers[findIndex] = {
          questionId: allTestQuestions[position]?.QuestionID,
          qanswer: "",
          qoption: "SKIP",
        };

        setSelectedAnswers(cloneAnswers);
        setFlag(!flag);
      } else {
        let position = currentPosition - 1;
        let answerClone = JSON.parse(JSON.stringify(selectedAnswers));
        answerClone[position] = {
          questionId: allTestQuestions[position]?.QuestionID,
          qanswer: "",
          qoption: "SKIP",
        };
        setSelectedAnswers(answerClone);
        setFlag(!flag);
      }
    }

    // switch to next test
    if (currentPosition !== allTestQuestions.length) {
      setCurrentPosition((currentPosition) => currentPosition + 1);
      setSelectedOption({
        questionId: "",
        qanswer: "",
      });
    }
    // final submit modal
    if (currentPosition === allTestQuestions.length) {
      setShow(true);
    }
  };

  // final submit mcq question
  const finalSubmit = async () => {
    try {
      console.log("Auto submit");
      console.log(allTestQuestions);
      console.log(selectedAnswers);
      setButtonLoading(true);
      const finalAnswer = allTestQuestions.map((data, i) => {
        if (selectedAnswers[i]?.questionId) {
          return selectedAnswers[i];
        } else {
          return {
            questionId: data?.QuestionID,
            qanswer: "",
            qoption: "SKIP",
          };
        }
      });

      const res = await userAttemptMCQTestSeries({
        SectionTestID: testId,
        answer: finalAnswer,
        testduration: userStayRef.current || 0,
      });

      if (res.code === 200) {
        toast.success("Test Submitted Successfully");
        setButtonLoading(false);
        navigate(`/dashboard/test-series/detail/${testId}/test-overview`);
      } else {
        failResponse(res);
        setButtonLoading(false);
      }
    } catch (err) {
      errorResponse(err);
      setButtonLoading(false);
    }
  };

  // end test
  const endTestHandler = async () => {
    try {
      const finalAnswer = allTestQuestions.map((data, i) => {
        if (selectedAnswers[i]?.questionId) {
          return selectedAnswers[i];
        } else {
          return {
            questionId: data?.QuestionID,
            qanswer: "",
            qoption: "SKIP",
          };
        }
      });

      setButtonLoading(true);
      const res = await userAttemptMCQTestSeries({
        SectionTestID: testId,
        answer: finalAnswer,
        testduration: userStayRef.current || 0,
      });

      if (res.code === 200) {
        toast.success("Test Submitted Successfully");
        setButtonLoading(false);
        navigate(`/dashboard/test-series/detail/${testId}/test-overview`);
      } else {
        failResponse(res);
        setButtonLoading(false);
      }
    } catch (err) {
      errorResponse(err);
      setButtonLoading(false);
    }
  };

  //   test previous question function
  const previousQuestionHandler = () => {
    setCurrentPosition((currentPosition) => currentPosition - 1);
    setFlag(!flag);
  };

  // ----------------------- getting selected course Data -------------------------//
  React.useLayoutEffect(() => {
    // get selected course
    const selectedCourseData = decryptData({
      data: sessionStorage.getItem("course"),
    });

    setCourse(selectedCourseData);
    // eslint-disable-next-line
  }, []);

  // ----------------------- getting test questions detail data -------------------------//
  React.useLayoutEffect(() => {
    // get test series detail
    const getTestQuestions = async () => {
      try {
        setLoading(true);
        const res = await userTestSeriesQuestions({
          courseId: [course.courseId],
          testId,
        });

        if (res.code === 200) {
          setAllQuestionsData(res.data || []);

          // filter all the the language question which is not available
          const questionArr = res.data.filter(
            (lan) => lan.questions?.length > 0
          );

          setAllLanguages(questionArr.map((lan) => lan.language) || []);
          setAllTestQuestions(questionArr[0]?.questions || []);
          setSelectedLanguages(questionArr[0]?.language || "");
          setLoading(false);
        } else {
          failResponse(res);
          setLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setLoading(false);
      }
    };

    // get test series detail
    const getTestDetails = async () => {
      try {
        const res = await userTestSeriesDetail({
          courseId: course.courseId,
          testId,
        });

        if (res.code === 200) {
          setTestDetails(res.data.testDetails || {});
          timerRef.current = 60 * +res.data.testDetails.TestDuration || 0;
          let tdr = 60000 * +res.data.testDetails.TestDuration || 0;
          setDuration(tdr);
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    if (course?.courseId) {
      getTestDetails();
      getTestQuestions();
    }
    // eslint-disable-next-line
  }, [course]);

  
  function HandleTimeronClik()
  {
      setDuration(Duration-1000); 
  }
  // ----------------------- timer function -------------------------//
  

  // ----------------------- set data based on languages -------------------------//
  React.useEffect(() => {
    const languageData = allQuestionsData?.find(
      (data) => data.language === selectedLanguages
    );
    setAllTestQuestions(languageData?.questions || []);
    setTotalOptions(languageData?.option || 0);
    let questionChecker =
      languageData?.questions[currentPosition - 1]?.QuestionID;

    if (!questionChecker) {
      setCurrentPosition(1);
    }

    // eslint-disable-next-line
  }, [selectedLanguages]);

  // ----------------------- set previous data based on previous button -------------------------//
  React.useEffect(() => {
    let position = currentPosition - 1;

    if (selectedAnswers[position]?.questionId) {
      setSelectedOption(selectedAnswers[position] || {});
    } else {
      setSelectedOption({ questionId: "", qanswer: "", qoption: "" });
    }
    // eslint-disable-next-line
  }, [flag]);

  // ----------------------- disabled inspect and right click-------------------------//
  function ctrlShiftKey(e, keyCode) {
    return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
  }

  document.onkeydown = (e) => {
    // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
    if (
      e.keyCode === 123 ||
      ctrlShiftKey(e, "I") ||
      ctrlShiftKey(e, "J") ||
      ctrlShiftKey(e, "C") ||
      (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
    )
      return false;
  };

  // alert before refresh
  React.useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  React.useEffect(() => {
    document.addEventListener(
      "contextmenu",
      function (e) {
        e.preventDefault();
      },
      false
    );
    return () =>
      document.removeEventListener("contextmenu", function (e) {
        e.preventDefault();
      });
  }, []);

  // stop to go previous page
  React.useEffect(() => {
    const handleDisableBackButton = () => {
      // window.history.pushState(null, null, window.location.href);
      navigate(`/dashboard/test-series/detail/${testId}/test`, {
        state: { auth: true },
      });
      window.onpopstate = () => {
        setShow(true);
        navigate(`/dashboard/test-series/detail/${testId}/test`, {
          state: { auth: true },
        });
      };
    };

    handleDisableBackButton();

    return () => {
      window.onpopstate = null;
    };
    // eslint-disable-next-line
  }, []);

  React.useLayoutEffect(() => {
    if (navType !== "PUSH") {
      navigate(1);
    }
    // eslint-disable-next-line
  }, [navType]);

  return (
    <>
      {!location?.state?.auth ? (
        <Navigate to="/dashboard/test-series" />
      ) : (
        <div className="testUnit">
          <div className="testHeader_wrap">
            <div className="testHeader">
              <div className="container">
                <div className="content_right">
                  <div className="logo">
                    <img src={Logo} alt="Shubhra Viraj" loading="lazy" />
                  </div>
                  <div className="duration">
                    <FaRegClock />
                   
                    <Countdown date={clientTime + Duration} onTick={HandleTimeronClik} onComplete={finalSubmit}  renderer={renderer}> 
                                                                   
                                            </Countdown>
                  </div>
                </div>
                <div className="content_left">
                  <button
                    type="button"
                    className="dashboard_button fill"
                    onClick={() => setShow(true)}
                  >
                    End test
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="testUnit_content spacing">
            {/* ************ loading component ************* */}
            {loading && <TestUnitSkeleton />}

            {/* ************ main component ************* */}
            {!loading && (
              <div className="container">
                <div className="question_content_wrap">
                  <div className="question_content">
                    <div className="questionNumber_wrap">
                      <h5 className="questionNumber">
                        Question {currentPosition} of {allTestQuestions.length}{" "}
                        :
                        <select
                          value={selectedLanguages}
                          onChange={({ target }) =>
                            setSelectedLanguages(target.value)
                          }
                        >
                          {allLanguages &&
                            allLanguages.map((data) => {
                              return (
                                <option key={data} value={data}>
                                  {data}
                                </option>
                              );
                            })}
                        </select>
                      </h5>
                    </div>

                    {/* iterate all questions */}
                    {allTestQuestions &&
                      allTestQuestions.map((question, i) => {
                        return (
                          <QuestionsComponent
                            key={i}
                            question={question}
                            disabled={i + 1 === currentPosition}
                            totalOptions={totalOptions}
                            optionSelectHandler={(data) =>
                              setSelectedOption({
                                questionId: data.questionId,
                                qanswer: data.qanswer,
                                qoption: "",
                              })
                            }
                            checked={(option) => {
                              return selectedOption?.qanswer === option;
                            }}
                            index={i + 1}
                          />
                        );
                      })}
                  </div>
                  <div className="button_section">
                    <div className="button_wrap">
                      <button
                        type="button"
                        className="dashboard_button blue"
                        onClick={previousQuestionHandler}
                        disabled={currentPosition === 1}
                      >
                        Previous
                      </button>
                      <button
                        type="button"
                        className="dashboard_button blue"
                        onClick={skipQuestionHandler}
                      >
                        Skip
                      </button>
                    </div>
                    <div className="button_wrap">
                      <button
                        onClick={reviewQuestionHandler}
                        type="button"
                        className="dashboard_button"
                        disabled={!selectedOption?.qanswer}
                      >
                        Review
                      </button>
                      <button
                        type="button"
                        className="dashboard_button green"
                        onClick={nextQuestionHandler}
                        disabled={!selectedOption?.qanswer}
                      >
                        Save & Next
                      </button>
                    </div>
                  </div>
                </div>

                {/* question attempt summery */}
                <ReviewComponent
                  allQuestions={allTestQuestions}
                  questionSelection={(number) => {
                    setCurrentPosition(number);
                    setFlag(!flag);
                  }}
                  currentPosition={currentPosition}
                  selectedAnswers={selectedAnswers}
                />
              </div>
            )}
          </div>

          {/* //////////////////////////// Modal /////////////////////////// */}

          <Modal
            className="endtestModal"
            show={show}
            onHide={() => setShow(false)}
            backdrop="static"
          >
            <Modal.Body>
              <div className=" content">
                <img src={OBJECTSimg} alt="End Test" loading="lazy" />
                <h4>End the test</h4>
                <p>You Can not resume you test after submission</p>
                <div className="score_button_wrap">
                  <div className="score_button red">
                    <b>{allTestQuestions?.length ?? 0}</b>
                    <span>Total questions</span>
                  </div>
                  <div className="score_button green">
                    <b>
                      {selectedAnswers.filter((data) => data?.qoption === "")
                        ?.length ?? 0}
                    </b>
                    <span>Attempted</span>
                  </div>
                  <div className="score_button yellow">
                    <b>
                      {selectedAnswers.filter(
                        (data) => data?.qoption === "Review"
                      )?.length ?? 0}
                    </b>
                    <span>Review</span>
                  </div>
                  <div className="score_button white">
                    <b>
                      {" "}
                      {selectedAnswers.filter(
                        (data) => data?.qoption === "SKIP"
                      )?.length ?? 0}
                    </b>
                    <span>Skip</span>
                  </div>
                </div>
              </div>
              <div className="button_wrap">
                {/* hide button if time is expire */}
                {!expiry && (
                  <button
                    type="button"
                    className="dashboard_button"
                    onClick={() => setShow(false)}
                  >
                    Resume
                  </button>
                )}

                {/* hide end test button on last submit */}
                {currentPosition === allTestQuestions.length && (
                  <>
                    <button
                      type="button"
                      className="dashboard_button green"
                      onClick={finalSubmit}
                      ref={buttonRef}
                    >
                      Submit
                    </button>
                  </>
                )}

                {currentPosition !== allTestQuestions.length && (
                  <button
                    type="button"
                    className="dashboard_button active"
                    onClick={endTestHandler}
                    ref={buttonRef}
                  >
                    End test
                  </button>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default TestUnit;
