import React from "react";
import { Nav, Tab } from "react-bootstrap";
import CalenderViewLectures from "../Section/CalenderViewLectures";
import SubjectByLectures from "../Section/SubjectByLectures";
import NotWatched from "../Section/NotWatch";
import ExceptionalLectures from "../Section/ExceptionalLectures";
import ListLectures from "../Section/ListLectures";
import LectureByNotes from "../Section/LectureByNotes";
import { useCourse } from "context/context";

const NormalLectureSection = () => {
  const { course } = useCourse();
  const [type, setType] = React.useState("list");

  return (
    <>
      <div className="lecture_content spacing">
        <div className="container">
          <Tab.Container id="left-tabs-example" defaultActiveKey="List_view">
            <div className="dashboard_tab">
              <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link
                    eventKey="List_view"
                    onClick={() => setType("list")}
                  >
                    List view
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="Calendar_view"
                    onClick={() => setType("calender")}
                  >
                    Calendar view
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    eventKey="Lectures_by_subjects"
                    onClick={() => setType("subject")}
                  >
                    Lectures by subjects
                  </Nav.Link>
                </Nav.Item>
                {course?.ExpLectures > 0 && (
                <Nav.Item>
                  <Nav.Link
                    eventKey="exceptional_access"
                    onClick={() => setType("exceptional")}
                  >
                    Exceptional access
                  </Nav.Link>
                </Nav.Item>
                )}

                <Nav.Item>
                  <Nav.Link
                    eventKey="lectureByNotes"
                    onClick={() => setType("lectureByNotes")}
                  >
                    Lecture By Notes
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              {/* calender screen */}
              {type === "calender" && <CalenderViewLectures />}

              {/* list screen */}
              {type === "list" && <ListLectures />}

              {/* subject screen */}
              {type === "subject" && <SubjectByLectures />}

              {/* not watched screen */}
              {type === "notWatched" && <NotWatched />}

              {/* lecture by note screen */}
              {type === "lectureByNotes" && <LectureByNotes />}

              {/* exceptional screen */}
              {type === "exceptional" && <ExceptionalLectures />}
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default NormalLectureSection;
