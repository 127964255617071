import React, { useState } from "react";
import { Image18, Image20, Image21, Image30, Logo } from "assets/images";
import "./Contactus.scss";
import { toast } from "react-hot-toast";
import { Input, Select, TextArea } from "components/Form";
import useButtonLoader from "hooks/useButtonLoader";
import { contactUs, getBillingCoursesForContact } from "services/home";
import { errorResponse, failResponse } from "config/config";

const ContactusForm = ({ center, centerId, address, coursetitle }) => {
  const [allCourses, setAllCourses] = useState([]);

  const [contactData, setContactData] = useState({
    courseId: "",
    fullName: "",
    email: "",
    mobile: "",
    query: "",
  });

  const [errors, setErrors] = useState({
    courseId: "",
    fullName: "",
    email: "",
    mobile: "",
    query: "",
  });

  const [buttonRef, setButtonLoading] = useButtonLoader("Submit", true);

  //   onchange handler
  const onChangeHandler = ({ target }) => {
    const { name, value } = target;
    if (name === "mobile") {
      setContactData({ ...contactData, [name]: value.replace(/\D/g, "") });
    } else {
      setContactData({ ...contactData, [name]: value });
    }
  };

  //   validation function
  const handleValidation = () => {
    let errorField = {};
    let formIsValid = true;

    // if (!contactData.centerId) {
    //   errorField["centerId"] = "Please Select Center";
    //   formIsValid = false;
    // }
    if (!contactData.courseId) {
      errorField["courseId"] = "Please Select Course";
      formIsValid = false;
    }
    if (!contactData.fullName) {
      errorField["fullName"] = "Please Enter Full Name";
      formIsValid = false;
    }
    if (!contactData.email) {
      errorField["email"] = "Please Enter Email";
      formIsValid = false;
    }
    if (!contactData.mobile) {
      errorField["mobile"] = "Please Enter Mobile";
      formIsValid = false;
    }
    if (!contactData.query) {
      errorField["query"] = "Please enter some message";
      formIsValid = false;
    }

    setErrors(errorField);
    return formIsValid;
  };

  //   help submit handler
  const contactSubmitHandler = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      try {
        setButtonLoading(true);
        const { courseId, fullName, email, mobile, query } = contactData;
        const res = await contactUs({
          centerId,
          billingCourseId: courseId,
          fullname: fullName,
          email,
          mobile,
          query,
        });

        if (res.code === 200) {
          toast.success(res.message);
          setButtonLoading(false);
          setContactData({
            courseId: "",
            fullName: "",
            email: "",
            mobile: "",
            query: "",
          });
        } else {
          failResponse(res);
          setButtonLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setButtonLoading(false);
      }
    } else {
      toast.error("Please fill all fields");
    }
  };

  // --------------- getting all required masters data ----------------//
  React.useLayoutEffect(() => {
    // const getCentersData = async () => {
    //   try {
    //     const res = await getCenters();
    //     if (res.code === 200) {
    //       setAllCenters(res.data.centers.rows || []);
    //     } else {
    //       failResponse(res);
    //     }
    //   } catch (err) {
    //     errorResponse(err)
    //   }
    // };

    const getCourseData = async () => {
      try {
        const res = await getBillingCoursesForContact();
        if (res.code === 200) {
          setAllCourses(res.data.billingCoures || []);
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    // calls all the master functions
    // getCentersData();
    getCourseData();
    // eslint-disable-next-line
  }, []);

  // React.useEffect(() => {
  //   if (centerId) {
  //     setContactData({ ...contactData, centerId: centerId });
  //   }
  //   // eslint-disable-next-line
  // }, [centerId]);

  return (
    <div className="contactus_form spacing">
      <div className="container">
        <div className="form_content">
          <div className="image_section">
            <div className="image_wrap">
              <img
                src={Image18}
                alt="teacher"
                className="circle"
                loading="lazy"
              />
              <img
                src={Image21}
                alt="teacher"
                className="radius"
                loading="lazy"
              />
              <img
                src={Image20}
                alt="teacher"
                className="radius"
                loading="lazy"
              />
              <img
                src={Image30}
                alt="teacher"
                className="dShape"
                loading="lazy"
              />
            </div>
            <span>Learn from the best and </span>
            <h5>become top rankers like them</h5>
            <p>
              Join India’s leading institute for Civil services preparation.{" "}
            </p>
            <img src={Logo} alt="" loading="lazy" />
          </div>
          <div className="detail_form">
            <div className="form_title">
              <h3>Contact us</h3>
              <span>CENTRE : {center || "NEW DELHI"} </span>
              <p>
                {address ||
                  "Metro station, 25, First floor, Pusa Rd, near Karol Bagh, New Delhi, Delhi 110005"}
              </p>
            </div>
            <form className="form">
              <div className="form_field_wrap">
                <div className="form_field">
                  <Input
                    placeholder="Enter full name"
                    onChange={onChangeHandler}
                    value={contactData.fullName}
                    name="fullName"
                    className={`input form-control `}
                    label={"Full Name"}
                    error={errors.fullName}
                    required
                  />
                </div>
                {/* <div className="form_field">
                  <Select
                    name="centerId"
                    placeholder="Select Center"
                    onChange={onChangeHandler}
                    value={contactData.centerId || ""}
                    error={errors.centerId}
                    className={`input form-control `}
                    label={"Center"}
                    required
                  >
                    <option value="">Select center </option>
                    {allCenters &&
                      allCenters.map(({ CenterID, CenterName }) => {
                        return (
                          <option value={CenterID} key={CenterID}>
                            {CenterName}
                          </option>
                        );
                      })}
                  </Select>
                </div> */}
                <div className="form_field">
                  <Select
                    name="courseId"
                    placeholder="Select Course"
                    onChange={onChangeHandler}
                    value={contactData.courseId || ""}
                    error={errors.courseId}
                    className={`input form-control `}
                    label={"Course"}
                    required
                  >
                    <option value="">{coursetitle || "Select Course"}</option>
                    
                    {allCourses &&
                      allCourses.map(({ id, CourseTitle }, i) => {
                        return (
                          <option value={id} key={i}>
                            {CourseTitle}
                          </option>
                        );
                      })}
                  </Select>
                </div>
              </div>

              <div className="form_field_wrap">
                <div className="form_field">
                  <Input
                    placeholder="Enter your Email Address"
                    onChange={onChangeHandler}
                    value={contactData.email}
                    name="email"
                    className={`input form-control `}
                    label={"Email ID"}
                    error={errors.email}
                    required
                  />
                </div>
                <div className="form_field">
                  <Input
                    placeholder="Enter Mobile Number"
                    onChange={onChangeHandler}
                    value={contactData.mobile}
                    name="mobile"
                    className={`input form-control `}
                    label={"Mobile"}
                    error={errors.mobile}
                    maxLength={10}
                    required
                  />
                </div>
              </div>

              <div className="form_field">
                <TextArea
                  placeholder="Enter your query here, and our counsellor will contact you"
                  onChange={onChangeHandler}
                  value={contactData.query}
                  name="query"
                  className={`input form-control `}
                  label={"Message"}
                  error={errors.query}
                  required
                />
              </div>

              <div className="button_wrap text-center">
                <button
                  className="button"
                  onClick={contactSubmitHandler}
                  ref={buttonRef}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactusForm;
