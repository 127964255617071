import React, { useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Courseimg4 } from "assets/images";
import { errorResponse, failResponse } from "config/config";
import { countArray } from "config/utils";
import CourseSkeleton from "components/skeleton/CourseSkeleton";
import { getAllCourses } from "services/courses/courses";

import {
  getMasterCourseCategories,
  getMasterCourseSubCategories,
} from "services/master";
const DemoCourses = require('assets/images/GS DEMO COURSES.png');
const OptionalDemoCourses = require('assets/images/OPTIONAL DEMO COURSES.png');
const GeneralStudies = require('assets/images/SOCIOLOGY.png');
const Csat = require('assets/images/Csat.png');
const Essay = require('assets/images/ESSAY.png');
const Modular = require('assets/images/MODULAR.png');
const QEPGSCrashCourse = require('assets/images/QEP GS Crash Course For Prelims.png')
const ncert = require('assets/images/ncert.png')
const IGSOptionalClassroom = require('assets/images/Integrated GS And Optional Classroom Course.png')
const IGSOptionalTablet = require('assets/images/Integrated GS And Optional Tablet Course.png')
const HistoryOptional = require('assets/images/history optional course.png')
const Ondemandcourse = require('assets/images/ondemand optional course.png')
const PSIROptional = require('assets/images/psir optional courses.png')
const SociologyOptional = require('assets/images/sociology optional course.png')
const RegionalCenter = require('assets/images/Regional Centre.png')
const GSFoundationCourse = require('assets/images/4.png')
const GSStudyCourse = require('assets/images/5.png')
const GSTestSeries = require('assets/images/6.png')
const BhubaneswarCentreCourse = require('assets/images/Bhubaneswar Centre Course.png')
const LucknowCenterCourse = require('assets/images/Lucknow Centre Course.png')
const MumbaiCenterCourse = require('assets/images/Mumbai Centre Course.png')
const PuneCenterCourse = require('assets/images/Pune Centre Course.png')
const Ethics = require('assets/images/Ethics.png')
const HistoryLiteratureCourses = require('assets/images/History Literature Courses.png')
const HistoryOptionalCourses = require('assets/images/History Optional Courses.png')
const GeographyOptionalCourses = require('assets/images/Geography Optional Courses.png')

const TYPES = [
  {
    id: "UPSC",
    name: "UPSC",
  },
  {
    id: "SPSC",
    name: "State PSC",
  },
];

const OurCourses = () => {
  const [type, setType] = useState(TYPES[0]?.id);
  const [loading, setLoading] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [allSubCategory, setAllSubCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    id: "",
    name: "",
  });

  const typSelectionHandler = (type) => {
    setType(type);
    setAllCategories([]);
    setAllSubCategory([]);
    setSelectedCategory({
      id: "",
      name: "",
    });
  };

  // --------------- getting course according to subject ----------------//
  React.useEffect(() => {
    // get course category
    const getCourseCategory = async () => {
      try {
        const res = await getMasterCourseCategories({ type });

        if (res.code === 200) {
          setAllCategories(res.data.courseCategories || []);
          if (res.data?.courseCategories?.length > 0) {
            const { id, name } = res.data?.courseCategories[0];
            setSelectedCategory({ id, name });
          }
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    getCourseCategory();
  }, [type]);

  // get sub category by category
  // React.useEffect(() => {
  //   const getSubCategory = async () => {
  //     try {
  //       setLoading(true);
  //       const res = await getMasterCourseSubCategories({
  //         courseTypeId: selectedCategory?.id,
  //       });

  //       if (res.code === 200) {
  //         setAllSubCategory(res.data.courseSubCategories || []);
  //         setLoading(false);
  //       } else {
  //         failResponse(res);
  //         setLoading(false);
  //       }
  //     } catch (err) {
  //       errorResponse(err);
  //       setLoading(false);
  //     }
  //   };
  //   selectedCategory?.id && getSubCategory();
  // }, [selectedCategory]);
  React.useEffect(() => {
    // get courses
    const getCourses = async () => {
      try {
        const res = await getAllCourses({
          language: "EN",
          page: 1,
          examType: [],
          courseType: [],
          stateId: [],
          courseCategory: selectedCategory.id,
          CourseSubCategory: '', // No need to specify CourseSubCategory
          search: "",
          platformType: "chrome",
          //courseTypeId: selectedCategory?.id,
        });
        if (res.code === 200) {
          setAllSubCategory(res.data.courseSubCategories.rows || []);
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    selectedCategory?.id && getCourses();
  }, [selectedCategory]);

  return (
    <>
      <div className="our_courses spacing">
        <div className="container">
          <div className="page_title">
            <h3>Our special courses</h3>
            <p>
              Our specially designed and most recommended courses for upcoming
              exams
            </p>
          </div>

          <div className="course_wrap">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <div className="nav_tab">
                <Nav variant="pills" className="">
                  {TYPES &&
                    TYPES.map((data) => {
                      return (
                        <Nav.Item
                          key={data.id}
                          onClick={() => typSelectionHandler(data.id)}
                        >
                          <Nav.Link
                            className={`${type === data.id && "active"}`}
                          >
                            {data.name}
                          </Nav.Link>
                        </Nav.Item>
                      );
                    })}
                </Nav>
              </div>
              {/* Tab Content */}
              <div className="navtab_content">
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <Tab.Container id="union-public">
                      <div className="general_study_tab">
                        <Nav variant="pills" className="">
                          {allCategories.map(({ id, name }) => {
                            return (
                              <Nav.Item
                                key={id}
                                className={`mx-2`}
                                onClick={() =>
                                  setSelectedCategory({ id, name })
                                }
                              >
                                <Nav.Link
                                  className={`${id === selectedCategory?.id ? "active" : ""
                                    }`}
                                >
                                  {name}
                                </Nav.Link>
                              </Nav.Item>
                            );
                          })}
                        </Nav>
                      </div>
                      <div className="general_study_content">
                        <Tab.Content>
                          <div className="content">
                            {/************** loading component **************/}
                            {loading && (
                              <ul className="course_list">
                                {countArray(5).map((data) => {
                                  return (
                                    <li className="" key={data}>
                                      <CourseSkeleton
                                        width="100%"
                                        height={130}
                                      />
                                    </li>
                                  );
                                })}
                              </ul>
                            )}

                            {/************** not found component **************/}
                            {!loading && allSubCategory.length <= 0 && (
                              <div className="coursecard_wrap">
                                <div className="content text-center text-danger py-5">
                                  <h6>No Data Available</h6>
                                </div>
                              </div>
                            )}

                            {/************** main component **************/}
                            {!loading && allSubCategory.length > 0 && (
                              <ul className="course_list">
                                {allSubCategory?.map(({ id, name }, i) => {
                                  let imageSrc;
                                  if (name === "GS Demo Courses") {
                                    imageSrc = DemoCourses;
                                  } else if (name === "Optional Demo Courses") {
                                    imageSrc = OptionalDemoCourses;
                                  } else if (name === "General Studies") {
                                    imageSrc = GeneralStudies;
                                  } else if (name === "CSAT") {
                                    imageSrc = Csat;
                                  } else if (name === "Essay") {
                                    imageSrc = Essay;
                                  } else if (name === "Modular") {
                                    imageSrc = Modular;
                                  } else if (name === "Sociology") {
                                    imageSrc = GeneralStudies;
                                  } else if (name === "GS Course") {
                                    imageSrc = GeneralStudies;
                                  }else if (name === "QEP GS Crash Course for Prelims") {
                                    imageSrc = QEPGSCrashCourse;
                                  }else if (name === "NCERT") {
                                    imageSrc = ncert;
                                  }else if (name === "Integrated GS and Optional Classroom Course") {
                                    imageSrc = IGSOptionalClassroom;
                                  }else if (name === "Integrated GS And Optional Tablet Course") {
                                    imageSrc = IGSOptionalTablet;
                                  
                                  }else if (name === "History Optional Course") {
                                    imageSrc = HistoryOptional;
                                  }else if (name === "On Demand Courses") {
                                    imageSrc = Ondemandcourse;
                                  }else if (name === "Shubhra Ranjan Course") {
                                    imageSrc = PSIROptional;
                                  }
                                  else if (name === "असली Sociology Course") {
                                    imageSrc = SociologyOptional;
                                  }
                                  else if (name === "Regional Centre") {
                                    imageSrc = RegionalCenter;
                                  }else if (name === "GS Foundation Course") {
                                    imageSrc = GSFoundationCourse;
                                  }else if (name === "GS Crash Course") {
                                    imageSrc = GSStudyCourse;
                                  }else if (name === "GS Test Series") {
                                    imageSrc = GSTestSeries;
                                  }else if (name === "Bhubaneswar Centre Course") {
                                    imageSrc = BhubaneswarCentreCourse;
                                  }else if (name === "Lucknow Centre Course") {
                                    imageSrc = LucknowCenterCourse;
                                  }else if (name === "Mumbai Centre Course") {
                                    imageSrc = MumbaiCenterCourse;
                                  }else if (name === "Pune Centre Course") {
                                    imageSrc = PuneCenterCourse;
                                  }else if (name === "ETHICS") {
                                    imageSrc = Ethics;
                                  }else if (name === "Hindi Literature (हिन्दी साहित्य) Optional Course") {
                                    imageSrc = HistoryLiteratureCourses;
                                  }else if (name === "History Optional Courses") {
                                    imageSrc = HistoryOptionalCourses;
                                  }else if (name === "Geography Optional Course") {
                                    imageSrc = GeographyOptionalCourses;
                                  }
                                   else {
                                    imageSrc = Courseimg4; // Default image
                                  }
                                   return (
                                    <li key={id}>
                                      <Link
                                        to={`/course-listing/course/${selectedCategory?.name}/${selectedCategory?.id}/${name}/${id}`}
                                      >
                                        <img
                                          src={imageSrc}
                                          alt={name}
                                          loading="lazy"
                                        />
                                        <strong>{name}</strong>
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            )}


                            {/* <div className="button_wrap">
                              <Link to="" className="button">
                                Enroll Now
                                <span className="icon">
                                  <BsArrowRight />
                                </span>
                              </Link>
                            </div> */}
                          </div>
                        </Tab.Content>
                      </div>
                    </Tab.Container>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurCourses;
