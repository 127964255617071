 import { courseAccess, momentDateFormat } from "config/utils";
import { Calendarimg, Teacherimg, UserGroupimg } from "assets/icons";

import { BiRupee } from "react-icons/bi";

import { BsArrowRight } from "react-icons/bs";
import { useAddons } from "context/context";
import useCookie from "hooks/useCookie";
import moment from "moment";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";



const BillingSummery = ({
  courseData,
  allAddOns = [],
  id: courseId,
  enrollAction,
  courseRef,
}) => {
  const { addons, setAddons } = useAddons();
  const { cookies } = useCookie();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if AdmissionEndDate is expired
    if (moment().isAfter(courseData.AdmissionEndDate)) {
      // Redirect to the home page
      navigate("/");
    }
  }, [courseData.AdmissionEndDate, navigate]);

  // Return null if AdmissionEndDate is expired to prevent rendering the component
  if (moment().isAfter(courseData.AdmissionEndDate)) {
    return null;
  }


  //   addons handler
  const addonsHandler = (data) => {
    const checkedIncludes = addons[courseId]
      ?.map((data) => data.id)
      .includes(data.id);

    if (checkedIncludes) {
      const mainData = JSON.parse(JSON.stringify(addons[courseId] || []));
      let filterData = mainData.filter((main) => main.id !== data.id);
      setAddons({ ...addons, [courseId]: filterData });
    } else {
      const mainData = JSON.parse(JSON.stringify(addons[courseId] || []));
      mainData.push(data);
      setAddons({ ...addons, [courseId]: mainData });
    }
  };

  const hideEnrollBtn = () => {
    if (+courseData.CourseFee === 0 && cookies) {
      return false;
    }
    return true;
  };

  return (
    <>
      <div className="billing_summery" ref={courseRef}>
        <div className={`billing_card ${allAddOns?.length <= 0 && "w-75"}`}>
          <h4>Selected Course</h4>
          <div className="description">
            <strong>
              {courseData.DisplayNameHindi || courseData.DisplayNameEng}
            </strong>

            <ul>
              {/* <li>
                <img src={Calendarimg} alt="" loading="lazy" />
                Starting from&nbsp;
                <b>{momentDateFormat(courseData.CourseStartDate)}</b>
              </li> */}
              <li>
              <img src={Calendarimg} alt="" loading="lazy" />
              Starting from&nbsp;
              <b>{+courseData?.isOnDemand === 1 ? moment().format("DD MMM YYYY") : momentDateFormat(courseData.CourseStartDate)}</b>
            </li>
              {/* <li>
                <img src={UserGroupimg} alt="" loading="lazy" />
                {courseData.NumberOfSeat || 0}
                &nbsp;Seats Available
              </li> */}
              <li>
                <img src={Teacherimg} alt="" loading="lazy" />
                {courseData.CourseType === "Both"
                  ? "Hybrid"
                  : courseData.CourseType}{" "}
                Course
              </li>
              {/* <li>
                <img src={Teacherimg} alt="" loading="lazy"/>
                Nirav Bharadvaj(Static)
              </li> */}
              <li className="course_fee">
                <del>
                  <BiRupee />
                  {courseData.CourseFee}/-
                </del>
                <h6 className="price">
                  <BiRupee />
                  {courseData.CourseDiscountedAmount}
                  /-
                  <small>Inclusive of taxes</small>
                </h6>
              </li>
            </ul>
          </div>
        </div>

        {/* addon cards */}
        {allAddOns?.length > 0 && (
          <div className="billing_card addons">
            <h4>ADD-ON courses</h4>
            <div className="description">
              <strong>
                {courseData.DisplayNameHindi || courseData.DisplayNameEng}
              </strong>

              <ul>
                {/* all addons listing */}
                {allAddOns?.length > 0 ? (
                  allAddOns.map(({ id, price, name }, i) => {
                    return (
                      <li
                        key={i}
                        onClick={() => addonsHandler({ id, name, price })}
                      >
                        <input
                          type="checkbox"
                          onChange={() => {}}
                          checked={
                            addons[courseId]
                              ?.map((data) => data.id)
                              ?.includes(id) || false
                          }
                        />
                        <label>
                          {name || "N/A"}
                          <small>₹{price} /-</small>
                        </label>
                      </li>
                    );
                  })
                ) : (
                  <h6 className="text-center">No Addons Available</h6>
                )}
              </ul>
            </div>
          </div>
        )}

        <div className="card_summery">
        {courseData.CourseFor === "RC" ? (
  <h5>For best deal contact RC</h5>
) : <>
<h3>TOTAL</h3>
<del>
  <BiRupee /> {courseData.TotalAmount}/-
</del>
<h6 className="price">
  <BiRupee />
  {courseData.TotalDiscountedAmount} /-
</h6>
<small>You save ₹ {courseData.TotalSaving}</small>
</>}

          <div className="button_wrap padding">
            {courseData.CourseFor === "RC" &&
            courseData.CourseType === "Classroom" ? (
              <button disabled={true} className="button">
                Purchase from regional centre
                <span className="icon">
                  <BsArrowRight />
                </span>
              </button>
            ) : (
              hideEnrollBtn() && (
                <button
                  onClick={enrollAction}
                  disabled={!courseAccess(courseData?.AdmissionStartDate)}
                  className="button"
                >
                  {courseAccess(courseData?.AdmissionStartDate)
                    ? "Enroll Now"
                    : "Coming Soon"}
                  <span className="icon">
                    <BsArrowRight />
                  </span>
                </button>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingSummery;
