import React, { useLayoutEffect ,useState } from "react";
import { Navigate, useNavigate, Outlet, Route, Routes } from "react-router-dom";
import "./MyToken.scss";
import { getMasterCenters,getCentersListforTokens } from "services/master";
import { errorResponse, failResponse, normalEncryptData } from "config/config";
import { GenerateToken,getTokenDetails } from "services/courses/courses";
import { toast } from "react-hot-toast";
import { Modal} from 'react-bootstrap'; // Import Modal and Table from React Bootstrap
import { BiCheckCircle,BiX } from 'react-icons/bi'; // Import the check circle icon

 

const MyToken = () => {
  const navigate = useNavigate();
  const [allCenters, setAllCenters] = useState([]);
  const [center, setCenter] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [token, setToken] = useState("");
  const [tokenDetails, setTokenDetails] = useState(null);

  const [generatedToken, setGeneratedToken] = useState(null);


 
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleCenterChange = (e) => {
    setCenter(e.target.value);
  };

  // --------------- getting all required masters data ----------------//
  // React.useLayoutEffect(() => {
  //   const gettokenData = async () => {
  //     try {
  //       const res = await getTokenDetails();
  //       if (res.code === 200) {
  //         setTokenDetails(res.data.centertokendetails || null);
  //         console.log(res.data.centertokendetails || []);

  //        } else {
  //         failResponse(res);
  //       }
  //     } catch (err) {
  //       errorResponse(err);
  //     }
  //   };
  //   gettokenData();
  //  }, []);


  useLayoutEffect(() => {
    const gettokenData = async () => {
      try {
        const res = await getTokenDetails();
        if (res.code === 200) {
          if(res.data.ProfileUpdated === 0)
          {
            navigate("/dashboard/update-user-profile");
          }
          const details = res.data.centertokendetails || null;
          setTokenDetails(details);
          if (details) {
            setToken(details.token);
          }
          console.log(details);
        } else {
          if(res.data.ProfileUpdated === 0)
          {
            navigate("/dashboard/update-user-profile");
          }
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };
    gettokenData();
  }, []);


   React.useLayoutEffect(() => {
    const getCentersData = async () => {
      try {
        const res = await getCentersListforTokens();
        if (res.code === 200) {
          setAllCenters(res.data.centerPage || []);
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };
     getCentersData();
   }, []);


   
const handleConfirmOrderClick = () => {
    confirmOrder(center); 
  };
  const confirmOrder = async (CenterID) => {
    try {
      setLoading(true);
      const res = await GenerateToken({ CenterID });
      setLoading(false);

      if (res.code === 200) {
        setGeneratedToken(res.data?.token);
        console.log("generated token",res.data?.token);
        setShowSuccessModal(true);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setLoading(false);
    }
  };
  // const confirmOrder = async (CenterID) => {
  //   try {
  //     setLoading(true);
  //     const res = await GenerateToken({CenterID : CenterID});
  //     setLoading(false);
      
  //      if (res.code === 200) {
  //       setGeneratedToken(res.data.token);
  //      // toast.success(res.message);
  //       setShowSuccessModal(true);
  //       setLoading(false);
       

  //     } else {
  //       toast.error(res.message);
  //       setLoading(false);
  //     }
  //   } catch (err) {
  //       toast.error(err?.response?.data?.message || err.message);
  //       setLoading(false);
  //   }
  // };

   
  return (
    

    // <div className="token-container">
    //   <h1>Generate Token Number</h1>
    //   <p className="error-message">Your token number has expired, please generate new token.</p>
      
    //   <label htmlFor="center-select">Choose the centre where you wish to take admission</label>
    //   <select
    //     id="center-select"
    //     value={center}
    //     onChange={handleCenterChange}
    //     className="center-select"
    //   >
       
    //      <option value="" disabled>Select Center</option>
    //     {allCenters.map((center) => (
    //       <option key={center.CenterID} value={center.CenterID}>
    //         {center.CenterName}
    //       </option>
    //     ))}
    //   </select>
    //   {center && (
    //     <>
    //       <div id="terms-checkbox-container">
    //         <input 
    //           type="checkbox" 
    //           id="terms-checkbox" 
    //           checked={isChecked} 
    //           onChange={handleCheckboxChange} 
    //         />
    //         <label htmlFor="terms-checkbox">
    //           By checking this you are agree to our <a href="#">Terms & Conditions</a>
    //         </label>
    //       </div>
    //       <button 
    //         className="generate-token-button" 
    //         disabled={!isChecked} 
    //          onClick={handleConfirmOrderClick}
    //       >
    //         Click here to generate new token number
    //       </button>
    //     </>
    //   )}
    //   <div className="notes">
    //     <p>Note:</p>
    //     <ul>
    //       <li>1) Token is center specific.</li>
    //       <li>2) Token generated for one center will not be valid to another center.</li>
    //       <li>3) Token is valid for 24 hours only.</li>
    //       <li>4) Student needs to provide this token number to the administrator to proceed for the admission.</li>
    //     </ul>
    //   </div>

    //  <p className="success-message" style={{fontSize:"18px"}}>A new token is generated.</p>
    //   <div className="notes">
    //     <label htmlFor="center-select" style={{marginBottom:"10px"}}>Your Offline Token Number is : <span style={{color:"red",fontSize:"large"}}>{"32514"}</span> </label>
       
    //     <ul style={{paddingLeft:"4px",fontWeight:"500"}}>
    //       <li> <span>Note:</span>  Please present token number to administrator to proceed for admission</li>
    //       <li>Your Token is valid for 24 Hours</li>
         
    //     </ul>
    //   </div>


    // <Modal
    //     show={showSuccessModal}
    //     onHide={() => setShowSuccessModal(false)}
    //     title="Success"
    //    >
    //     <div style={{ textAlign: 'center' }}>
    //     <BiCheckCircle style={{ color: 'green', fontSize: '64px' }} />
    //     <p> Token is Generated</p>
    //     <button
    //         onClick={() => setShowSuccessModal(false)}
    //         style={{
    //           position: 'absolute',
    //           top: '10px',
    //           right: '10px',
    //           color:"black",
    //           background: 'none',
    //           border: 'none',
    //           cursor: 'pointer',
    //         }}
    //       >
    //         <BiX style={{ fontSize: '24px' }} />
    //       </button>
    //   </div>
    //   </Modal>

    // </div>

  //   <div className="token-container">
  //   {generatedToken && tokenDetails ? (
  //     <>
  //       <p className="success-message" style={{ fontSize: "18px" }}>A new token is generated.</p>
  //       <div className="notes">
  //         <label htmlFor="center-select" style={{ marginBottom: "10px" }}>
  //           Your Offline Token Number is : <span style={{ color: "red", fontSize: "large" }}>{generatedToken}</span>
  //         </label>
  //         <ul style={{ paddingLeft: "4px", fontWeight: "500" }}>
  //           <li><span>Note:</span> Please present token number to administrator to proceed for admission</li>
  //           <li>Your Token is valid for 24 Hours</li>
  //         </ul>
  //       </div>
  //     </>
  //   ) : (
  //     <>
  //       <h1>Generate Token Number</h1>
  //       <p className="error-message">Your token number has expired, please generate new token.</p>
  //       <label htmlFor="center-select">Choose the centre where you wish to take admission</label>
  //       <select
  //         id="center-select"
  //         value={center}
  //         onChange={handleCenterChange}
  //         className="center-select"
  //       >
  //         <option value="" disabled>Select Center</option>
  //         {allCenters.map((center) => (
  //           <option key={center.CenterID} value={center.CenterID}>
  //             {center.CenterName}
  //           </option>
  //         ))}
  //       </select>
  //       {center && (
  //         <>
  //           <div id="terms-checkbox-container">
  //             <input
  //               type="checkbox"
  //               id="terms-checkbox"
  //               checked={isChecked}
  //               onChange={handleCheckboxChange}
  //             />
  //             <label htmlFor="terms-checkbox">
  //               By checking this you agree to our <a href="#">Terms & Conditions</a>
  //             </label>
  //           </div>
  //           <button
  //             className="generate-token-button"
  //             disabled={!isChecked}
  //             onClick={handleConfirmOrderClick}
  //           >
  //             Click here to generate new token number
  //           </button>
  //         </>
  //       )}
  //       <div className="notes">
  //         <p>Note:</p>
  //         <ul>
  //           <li>1) Token is center specific.</li>
  //           <li>2) Token generated for one center will not be valid at another center.</li>
  //           <li>3) Token is valid for 24 hours only.</li>
  //           <li>4) Student needs to provide this token number to the administrator to proceed with the admission.</li>
  //         </ul>
  //       </div>
  //     </>
  //   )}

  //   <Modal
  //     show={showSuccessModal}
  //     onHide={() => setShowSuccessModal(false)}
  //     title="Success"
  //   >
  //     <div style={{ textAlign: 'center' }}>
  //       <BiCheckCircle style={{ color: 'green', fontSize: '64px' }} />
  //       <p>Token is Generated</p>
  //       <button
  //         onClick={() => setShowSuccessModal(false)}
  //         style={{
  //           position: 'absolute',
  //           top: '10px',
  //           right: '10px',
  //           color: "black",
  //           background: 'none',
  //           border: 'none',
  //           cursor: 'pointer',
  //         }}
  //       >
  //         <BiX style={{ fontSize: '24px' }} />
  //       </button>
  //     </div>
  //   </Modal>
  // </div>


    
  <div className="token-container">
  {(generatedToken || tokenDetails) ? (
    <>
      <p className="success-message" style={{ fontSize: "18px" }}>A new token is generated.</p>
      <div className="notes">
        <label htmlFor="center-select" style={{ marginBottom: "10px" }}>
          Your Offline Token Number is : <span style={{ color: "red", fontSize: "large" }}>{generatedToken || tokenDetails.token}</span>
        </label>
        <ul style={{ paddingLeft: "4px", fontWeight: "500" }}>
          <li><span>Note:</span> Please present token number to administrator to proceed for admission</li>
          <li>Your Token is valid for 24 Hours</li>
        </ul>
      </div>
    </>
  ) : (
    <>
      <h1>Generate Token Number</h1>
      <p className="error-message">Your token number has expired, please generate new token.</p>
      <label htmlFor="center-select">Choose the centre where you wish to take admission</label>
      <select
        id="center-select"
        value={center}
        onChange={handleCenterChange}
        className="center-select"
      >
        <option value="" disabled>Select Center</option>
        {allCenters.map((center) => (
          <option key={center.CenterID} value={center.CenterID}>
            {center.CenterName}
          </option>
        ))}
      </select>
      {center && (
        <>
          <div id="terms-checkbox-container">
            <input
              type="checkbox"
              id="terms-checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="terms-checkbox">
              {/* By checking this you agree to our <a href="#">Terms & Conditions</a> */}
              By checking this you agree to our <a href="#">Terms & Conditions</a>
            </label>
          </div>
          <button
            className="generate-token-button"
            disabled={!isChecked}
            onClick={handleConfirmOrderClick}
          >
            Click here to generate new token number
          </button>
        </>
      )}
      <div className="notes">
        <p>Note:</p>
        <ul>
          <li>1) Token is center specific.</li>
          <li>2) Token generated for one center will not be valid at another center.</li>
          <li>3) Token is valid for 24 hours only.</li>
          <li>4) Student needs to provide this token number to the administrator to proceed with the admission.</li>
        </ul>
      </div>
    </>
  )}

  <Modal
    show={showSuccessModal}
    onHide={() => setShowSuccessModal(false)}
    title="Success"
  >
    <div style={{ textAlign: 'center' }}>
      <BiCheckCircle style={{ color: 'green', fontSize: '64px' }} />
      <p>Token is Generated</p>
      <button
        onClick={() => setShowSuccessModal(false)}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          color: "black",
          background: 'none',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        <BiX style={{ fontSize: '24px' }} />
      </button>
    </div>
  </Modal>
</div>


  );
};

export default MyToken;
