import React from "react";
import { BrowserRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "./App.scss";
import Routing from "./Routing/Routing";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ScrollToTop from "./ScrollToTop/ScrollToTop";
import { Toaster } from "react-hot-toast";
import { AxiosInterceptor } from "config/axiosInstance";
import {
  disableReactDevTools,
  errorResponse,
  failResponse,
} from "config/config";
import { onMessageListener } from "config/firebase";
import addNotification, { Notifications } from "react-push-notification";
import { notificationList } from "services/dashboard";
import { useCourse } from "context/context";
import WelcomePopup from "components/Popup/WelcomePopup";

const App = () => {
  // disable react dev tools in production modes
  if (process.env.NODE_ENV === "production") {
    process.env.GRPC_VERBOSITY = "DEBUG";
    process.env.GRPC_TRACE = "all";
    disableReactDevTools();
  }

  const { setAllNotifications } = useCourse();

  const getAllNotifications = async () => {
    try {
      const res = await notificationList({ page: 1 });

      if (res.code === 200) {
        setAllNotifications(res.data.notification || []);
      } else {
        failResponse(res);
      }
    } catch (err) {
      errorResponse(err);
    }
  };

  // this function triggered when new message arrive
  onMessageListener()
    .then(async (payload) => {
      await addNotification({
        title: payload.notification.title,
        message: payload.notification.body,
        theme: "light",
        duration: 60000,
      });

      // await getAllNotifications();
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <BrowserRouter>
      <AxiosInterceptor>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_LOGIN}>
          <div className="App position-relative">
            <Notifications position="top-right" />
            <WelcomePopup />
            <ScrollToTop />
            <Routing />
          </div>
          <Toaster
            position="top-right"
            // containerClassName="toastify"
            // toastOptions={{
            //   style: {
            //     boxShadow:
            //       "0 3px 10px rgb(0 0 0 / 0%), 0 3px 3px rgba(0, 0, 0, 0.05)",
            //     animation: "none",
            //   },
            // }}
          />
        </GoogleOAuthProvider>
      </AxiosInterceptor>
    </BrowserRouter>
  );
};

export default App;
