import React, { useState, useEffect, useRef } from "react";
import useVdocipher from "hooks/useVdocipher";
import OnDemandTimeTracking from "./OnDemandTimeTracking";
import { v4 as uuidv4 } from 'uuid';


const OnDemandPlayer = ({
  otp,
  playbackInfo,
  isTimeSpentApplicable,
  autoPlay = false,
  lastSeekBar = 0,
  sectionId,
  onClose,
  MAIN_PERCENTAGE = {},
  lectureDetail,
  lectureCompleteHandler,
}) => {
  const { loadVideo, isAPIReady, destroyVideo } = useVdocipher();
  const videoCountRef = useRef(1);
  const videoContainerRef = useRef();
  const [videoRef, setVideoRef] = useState(null);
  const [lctSession, setLctSession] = useState(uuidv4()); // Initialize with a unique ID

  const destroyCipher = () => {
    // videoRef.remove();
    setVideoRef(null);
    destroyVideo(videoContainerRef.current);
    videoContainerRef.current = null;
  };

  // useEffect(() => {
  //   if (videoCountRef.current === 1) {
  //     const video = loadVideo({
  //       otp,
  //       playbackInfo,
  //       container: videoContainerRef.current,
  //       configuration: {},
  //     });
  //     setVideoRef(video);
  //     videoCountRef.current += 1;
  //   }

  //   return () => {
  //     destroyCipher();
  //   };
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (!lctSession) {
      setLctSession(uuidv4());
    }
    if (videoCountRef.current === 1) {
      const video = loadVideo({
        otp,
        playbackInfo,
        container: videoContainerRef.current,
        configuration: {},
      });
      setVideoRef(video);
      videoCountRef.current += 1;
    }

    return () => {
      destroyCipher();
    };
    // eslint-disable-next-line
  }, [lctSession]);
  console.log("lctSession",lctSession)

  return (
    <>
      <div
        className="vdo-container vdocipher_video"
        ref={videoContainerRef}
      ></div>

      {/* if time spent applicable */}
      {isTimeSpentApplicable && (
        <OnDemandTimeTracking
          videoRef={videoRef}
          isAPIReady={isAPIReady}
          lastSeekBar={lastSeekBar}
          sectionId={sectionId}
          onClose={onClose}
          MAIN_PERCENTAGE={MAIN_PERCENTAGE}
          destroyCipher={destroyCipher}
          lectureDetail={lectureDetail}
          lectureCompleteHandler={lectureCompleteHandler}
          lctSession={lctSession}
        />
      )}
    </>
  );
};

export default OnDemandPlayer;
