import React, { useState,useEffect } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import ListCourseSkeleton from "components/skeleton/ListCourseSkeleton";
import LectureNoteFound from "components/Not-Found/LectureNotFound";
import { charLimit, countArray } from "config/utils";
import { lectureTimeTracking, onDemandLectureSection,OnDemandViewedListView} from "services/lecture";
import SuperTag from "components/SuperTag/SuperTag";
import { AiFillCheckCircle } from "react-icons/ai";
import { LectureShubhraImg } from "assets/images";
import { AiFillClockCircle } from "react-icons/ai";
import { Lock_lecture, VideoPlayIconImg, ViewdetailsImg } from "assets/icons";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import CommonModal from "components/modal-box/CommonModal";
import { FaExclamationCircle } from "react-icons/fa";
import { errorResponse, failResponse, normalEncryptData } from "config/config";
import { useCourse } from "context/context";
import { getUserIp } from "services/third-party";
import useButtonLoader from "hooks/useButtonLoader";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { useParams } from 'react-router-dom';


let GLOBAL_LOCAL_LIMIT = 3;
let MAIN_PERCENTAGE = 100;

const OnDemandViewedList = ({
  sectionId,
  sectionName,
  sectionDescription,
  courseId,
}) => {
  console.log("Viewed View");
  const [loading, setLoading] = useState(false);
  const [listLectures, setListLectures] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [descriptionModal, setDescriptionModal] = useState(false);
  const [guideModal, setGuideModal] = useState(false);
  const { course } = useCourse();
  const [buttonRef, setSubmitLoading] = useButtonLoader("Yes");
  const [totalLectures, setTotalLectures] = useState(0);

  const [mainIndex, setMainIndex] = useState(0);
  const [playingIndex, setPlayingIndex] = useState(0);
  const navigate = useNavigate();


  // Add these state variables to your functional component
  const [isNext, setIsNext] = useState(false);
  const [isPrev, setIsPrev] = useState(false);
  const [nextId, setNextId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 600);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  const getLiStyle = () => {
    return isMobile ? { paddingLeft: "0px" } : { paddingLeft: "230px" };
  };

  const getSectionStyle = () => {
    return isMobile ? { marginBottom: "10px" } : {};
  };
  
  // const getViewedLecturesList = async (page) => {
  //   try {
  //     setLoading(true);
  
  //     const res = await OnDemandViewedListView({
  //       section_id: sectionId,
  //       course_id: courseId,
  //       page: page,
  //     });
  
  //     if (res.code === 200) {
  //       const { rows, next, prev } = res.data.lectures;
  
  //       let findIndex = rows.findIndex((data) => data.isPlaying);
  //       setMainIndex(findIndex);
  
  //       const mainListing = rows.map((data, i) => {
  //         return {
  //           ...data,
  //           //...lockedFunction(data, findIndex, i),
  //         };
  //       });
  
  //       setListLectures(mainListing || []);
  //       setHasNextPage(next);
  //       setLoading(false);
  //     } else {
  //       failResponse(res);
  //       setLoading(false);
  //     }
  //   } catch (err) {
  //     errorResponse(err);
  //     setLoading(false);
  //   }
  // };

  const getViewedLecturesList = async (
    type = "",
    courseId = course.courseId,
    page = 1
   ) => {
    try {
      setLoading(true);
      const data = {page};
  
      if (type === "next") {
        if (listLectures[0]?.id) {
          Object.assign(data, {
            nextLecture: listLectures[listLectures.length - 1]?.id,
          });
        } else {
          Object.assign(data, {
            nextLecture: nextId,
          });
        }
      }
  
      if (type === "prev") {
        if (listLectures[0]?.id) {
          Object.assign(data, {
            previousLecture: listLectures[0]?.id,
          });
        } else {
          Object.assign(data, {
            previousLecture: nextId,
            currentLectureAvailable: false,
          });
        }
      }
  
      const res = await OnDemandViewedListView({
        section_id: sectionId,
        course_id: courseId,
        //search,
        ...data,
      });
  
      if (res.code === 200) {
        setListLectures(res.data.lectures.rows || []);
        setIsNext(res.data.next || false);
        setIsPrev(res.data.prev || false);
        setNextId(res.data.nextCourseId);
        setTotalLectures(res.data.totallectures);
        setLoading(false);
      } else {
        failResponse(res);
        setLoading(false);
      }
    } catch (err) {
      errorResponse(err);
      setLoading(false);
    }
  };

  const nextHandlerr = async () => {
    console.log("Next Button Clicked");
    await getViewedLecturesList("next", undefined, currentPage + 1);
    setCurrentPage(currentPage + 1);
  };
  
  // // previous handler
  const prevHandler = async () => {
    console.log("Prev Button Clicked");
    await getViewedLecturesList("prev", undefined, currentPage - 1);
    setCurrentPage(currentPage - 1);
  };
  
  React.useLayoutEffect(() => {
    sectionId && getViewedLecturesList();
    // eslint-disable-next-line
  }, [sectionId]);

  // React.useEffect(() => {
  //   sectionId && getViewedLecturesList(currentPage);
  // }, [sectionId, currentPage]);
  
  // const nextHandlerr = async () => {
  //   if (hasNextPage) {
  //     await setCurrentPage((prevPage) => prevPage + 1);
  //     getViewedLecturesList(currentPage + 1);
  //   }
  //  };
  
  // const prevHandler = async () => {
  //   if (currentPage > 1) {
  //     await setCurrentPage((prevPage) => prevPage - 1);
  //     getViewedLecturesList(currentPage - 1);
  //   }
  //  };


   

  return (
    <>
      <div className="daily_lecture">
        <div className="meeting_content">
          <div className="syllabus_paper">
          <ul style={{marginTop:"-35px"}}>
            <li style={getSectionStyle()}>
                <h6>{sectionName}</h6>
                <h6 style={isMobile ? { marginLeft: "15px" } : {}}>(Total Lectures: {totalLectures})</h6>
              </li>
              <li style={getLiStyle()}>
                <h6
                  onClick={() => {
                    setDescriptionModal(true);
                  }}
                >
                  View Syllabus
                </h6>
                 
                <span
                  className="info-icon"
                  onClick={() => {
                    setGuideModal(true);
                  }}
                >
                  
                  <BsFillInfoCircleFill />
                </span>
              </li>
            </ul>
          </div>

          <div className="d-flex justify-content-between py-2 align-items-center" style={{marginTop:"-32px"}}>
            <div className="d-flex">
              <button
                className="dashboard_button has_arrow prev"
                 disabled={!isPrev}
                onClick={prevHandler}
              >
                <FaChevronLeft /> Prev
              </button>

              <button
                className="dashboard_button has_arrow next"
                disabled={!isNext}
                onClick={nextHandlerr}
              >
                Next <FaChevronRight />
              </button>
            </div>
             
          </div>

          <div className="onDemandLectures">
            {/* ************ Loading Component *********** */}
            {loading && (
              <div className="d-flex justify-content-between  w-100">
                {countArray(4).map((data) => {
                  return (
                    <div className="onDemandCard_wrap p-0 mx-2" key={data}>
                      <div className="onDemandCard p-0">
                        <ListCourseSkeleton height={320} />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {/* iterate lectures */}

            {!loading && listLectures.length > 0 && (

              <>
              
                
                {listLectures.map(
                  (
                    {
                      LectureID,

                      LectureTitle,
                      AllocatedTime,
                      consumedTime,
                      remainingTime,
                      Duration,
                      vdocipherDetail,
                      lecturetime,
                      lectureStatus,
                      isPlaying,
                      playVideoPercantage,
                      locked,
                      file,
                    },
                    i
                  ) => {
                    return (
                      <div className="onDemandCard_wrap"
                       key={LectureID}
                       style={{ width: "10", marginBottom: "" }}
                       >
                        <div className="onDemandCard">
                          <div className="image_wrap">
                            <a>
                              <img
                                src={
                                  vdocipherDetail?.poster || LectureShubhraImg
                                }
                                alt={LectureTitle}
                              />
                               
                              <span className="serial_number">{i + 1}</span>
                            </a>
                          </div>
                          <div className="content">
                            <span className="red">Lec {i + 1}</span>
                            <h6>{charLimit(LectureTitle, 40)}</h6>
                            <ul>
                              <li className="light_blue">
                                <AiFillClockCircle /> {Duration}&nbsp;min
                              </li>
                              <li></li>

                              {lectureStatus !== 0 ? (
                                <li
                                  className={`${
                                    lectureStatus === 1 ? "green" : ""
                                  }`}
                                >
                                  <AiFillCheckCircle />
                                </li>
                              ) : (
                                <li></li>
                              )}
                            </ul>
                          </div>
                          <div className="View_details">
                            <span className="view_text">
                              <img src={ViewdetailsImg} alt="" loading="lazy" />
                              <span className="text">View details</span>
                            </span>
                            <ul>
                              <li>
                                <span>Lecture duration</span>
                                <span className="text"style={{fontSize:"11px",fontWeight:"600"}}>
                                  <AiFillClockCircle /> {Duration} min
                                </span>
                              </li>
                              <li>
                                <span>Allocated time</span>
                                <span className="text"style={{fontSize:"11px",fontWeight:"600"}}>
                                  <AiFillClockCircle /> {AllocatedTime} min
                                </span>
                              </li>
                              <li>
                                <span>Consumed time</span>
                                <span className="text"style={{fontSize:"11px",fontWeight:"600"}}>
                                  <AiFillClockCircle /> {consumedTime} min
                                </span>
                              </li>
                              <li>
                                <span>Remaining time</span>
                                <span className="text"style={{fontSize:"11px",fontWeight:"600"}}>
                                  <AiFillClockCircle /> {remainingTime} min
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
               
                
              </>
            )}

            {/* Not Found */}
            {!loading && listLectures.length <= 0 && (
              <>
                <LectureNoteFound title="Lecture" />
              </>
            )}
          </div>
        </div>
      </div>

      {/* usr consent message */}
      <CommonModal
        show={confirmModal}
        handleClose={() => setConfirmModal(false)}
        size="md"
      >
        <div className="logout">
          <span className="logout_icon">
            <FaExclamationCircle />
          </span>
          <h3>Alert</h3>
          <p>
            Message "I endorse that lecture number {mainIndex + 1} is completed
            watching. I also understand that allocated time for this lecture
            will be lapsed in-case it is not consumed completely and this
            lecture will not be available to watch in the future. Do you want to
            move to lecture number {mainIndex + 2}?
          </p>

          <div className="button_wrap center">
            <button
              className="dashboard_button"
              onClick={() => setConfirmModal(false)}
            >
              No
            </button>
            <button
              className="dashboard_button green"
              //onClick={() => nextHandler()}
              ref={buttonRef}
            >
              Yes
            </button>
          </div>
        </div>
      </CommonModal>

      {/* section description modal */}
      <CommonModal
        show={descriptionModal}
        handleClose={() => setDescriptionModal(false)}
        size="lg"
      >
        <div className="contain">
          <h6>{sectionName}</h6>
          <SuperTag>{sectionDescription}</SuperTag>
        </div>
      </CommonModal>

      {/* user guide modal */}
      <CommonModal
        show={guideModal}
        handleClose={() => setGuideModal(false)}
        size="lg"
      >
        <div className="contain">
          <h6>What is ‘On Demand’ Course?</h6>
          <p>
            It is a Foundation Course which gives super flexibility to the
            students when it comes to time and pace of the lectures. This
            lecture is similar as it happens in the regular classes. Som
            examples are, PSIR Optional by Shubhra Ranjan Ma’am, Sociology,
            History, Anthropology on demand courses.
          </p>
          <h6>What is maximum duration for the course?</h6>
          <p>
            The course duration is one year from the date of subscription. Thus,
            the student will have to either finish the lecture before this
            one-year duration or the course will automatically expire after one
            year from the date of subscription.
          </p>
          <h6>
            What is Lecture Duration, Allocated time, Remaining time of a
            particular lecture?
          </h6>
          <p>
            Note: all time is represented in hh:mm Lecture Duration– Actual time
            of a Lecture. Allocated Time – Time allocated to view a particular
            lecture. Normally if lecture duration is 60 minutes (01:00), then
            allocated time can be 02:00. Means 60 minutes of lecture can be seen
            for up-to 120 minutes. Remaining Time: Time remaining to view a
            particular lecture. If allocated time for a particular lecture is
            02:00 hours (120 minutes), And if lecture is seen for say 00:40
            minutes then remaining time for that particular lecture would be
            01:20 (80 minutes).
          </p>
          <h6>What should be the flow of the lectures?</h6>
          <p>
            Upon subscription, a student can select the Section of his choice.
            Upon entering the section, the student will have to start from the
            first lecture of that section. He can navigate back from the current
            lecture up to 2 previous lectures. However, he can watch previous
            lecture only if the allotted time is not finished.
          </p>
          <h6>Can I go back across sections?</h6>
          <p>No.</p>
          <h6>Does the remaining time of one lecture adds up to the next?</h6>
          <p>No.</p>

          {/* <p>
            <span className="text-danger">Note : </span>
            On demand course is created once for that subject. Like there will 3
            on demand courses 1) PSIR, 2) History 3) Anthropology These on
            demand courses will have todays date as start date always. End date
            for the student will be calculated from the duration. Like for
            History if duration is 6 months, If student 1 purchase it on 1 Nov,
            His end date will be 1Nov +6 months = 30 May If student 2 purchase
            it on 1 Dec, His end date will be 1Dec +6 months = 30 Jun
          </p> */}
        </div>
      </CommonModal>
    </>
  );
};

export default OnDemandViewedList;
