import useVdocipher from "hooks/useVdocipher";
import React, { useState, useEffect, useRef } from "react";
import CipherTimeTracking from "./CipherTimeTraking";
import { v4 as uuidv4 } from 'uuid';


const VideoJsCipherPlayer = ({
  otp,
  playbackInfo,
  isTimeSpentApplicable,
  autoPlay = false,
  lastSeekBar = 0,
  //lctSession = uuidv4()
  
}) => {
  const { loadVideo, isAPIReady } = useVdocipher();
  const videoCountRef = useRef(1);
  const videoContainerRef = useRef();
  const [videoRef, setVideoRef] = useState(null);
  const [lctSession, setLctSession] = useState(uuidv4()); // Initialize with a unique ID

  
  // useEffect(() => {
  //   if (videoCountRef.current === 1) {
  //     const video = loadVideo({
  //       otp,
  //       playbackInfo,
  //       container: videoContainerRef.current,
  //       configuration: {
  //         autoplay: false,
  //       },
  //     });
  //     setVideoRef(video);
  //     videoCountRef.current += 1;
  //   }
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    // Generate a new UUID only if lctSession is not provided
    if (!lctSession) {
      setLctSession(uuidv4());
    }

    if (videoCountRef.current === 1) {
      const video = loadVideo({
        otp,
        playbackInfo,
        container: videoContainerRef.current,
        configuration: {
          autoplay: false,
        },
      });
      setVideoRef(video);
      videoCountRef.current += 1;
    }
    // eslint-disable-next-line
  }, [lctSession]);
  
  console.log("lctSession",lctSession)

  return (
    <>
      <div
        className="vdo-container vdocipher_video"
        ref={videoContainerRef}
      ></div>

      {/* if time spent applicable */}
      {isTimeSpentApplicable && (
        <CipherTimeTracking
          videoRef={videoRef}
          isAPIReady={isAPIReady}
          lastSeekBar={lastSeekBar}
          lctSession={lctSession}
        />
      )}
    </>
  );
};

export default VideoJsCipherPlayer;
