import React, { useEffect, useState } from "react";
import "./Header.scss";
import { HeaderLogo, HeaderLogoNew } from "assets/images/index";
import { Link, NavLink, useMatch } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import {
  Combo,
  Foundation,
  Location,
  Menuclaseimg,
  Mobilemenu,
  Modular,
  Optional,
  Penimg,
} from "assets/icons/index";
import useCookie from "hooks/useCookie";
import Avatar from "./Avatar";
import TopBar from "./Topbar";
import CourseMenu from "./Menu/CourseMenu";
import ExamMenu from "./Menu/ExamMenu";
import CenterMenu from "./Menu/CenterMenu";
import MobileCourseMenu from "./mobile-menu/MobileCourse";
import MobileCenterMenu from "./mobile-menu/MobileCenter";
import MobileExamMenu from "./mobile-menu/MobileExam";
const Header = () => {
  const { cookies } = useCookie();
  const [scroll, setScroll] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const excludeDetailPage = useMatch("/course-detail/:name/:id");
  const excludeListingPage = useMatch("/course-listing");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
    document.body.classList.toggle("mobile_open", mobileMenu);
  }, [mobileMenu]);

  // remove the config from local if user change the journey
  useEffect(() => {
    if (!excludeDetailPage && !excludeListingPage) {
      sessionStorage.removeItem("config");
    }
  }, [excludeDetailPage, excludeListingPage]);

  return (
    <>
      {/* TopBar */}
      <TopBar />
      <div className={`header_wrapper ${scroll ? "scrollheader" : ""}`}>
        <header className="header">
          <div className="container">
            <div
              className="mobile_button"
              onClick={() => {
                setMobileMenu(!mobileMenu);
              }}
            >
              <img src={Mobilemenu} alt="" loading="lazy" />
            </div>
            <div className="logo">
              <Link to="/">
                <img src={HeaderLogoNew} alt="Shubra ranjan" loading="lazy" />
              </Link>
            </div>
            <nav className="navigation">
              <ul>
                {/* Menu-1 */}
                <CourseMenu />
                {/* Menu-2 */}
                <ExamMenu />
                {/* Menu-3 */}
                

                {/* <li>
                  <NavLink to="http://freeresources.shubhraviraj.in/" className="nav_link" target="_blank" rel="noopener noreferrer">
                    Free Resources
                  </NavLink>
                </li> */}
                
                {/* Menu-4 */}
                <li>
                  <NavLink to="https://currentaffairs.shubhraranjan.com/" className="nav_link" target="_blank" rel="noopener noreferrer">
                    Current Affairs & Blogs{" "}
                  </NavLink>
                </li>
                <CenterMenu/>

                {/* Menu-5 */}
                <li>
                  <NavLink to="/OurStory" className="nav_link">
                    Our Story{" "}
                    {/* <span className="icon">
                      <MdKeyboardArrowDown />
                    </span> */}
                  </NavLink>
                </li>
               
              </ul>
              <div className="button_wrap header_button">
                {/* if user is logged in */}
                {cookies && <Avatar type="home" />}

                {/* if user is not logged in  */}
                {!cookies && (
                  <Link to="/login" className="button">
                    Sign In / Sign Up
                  </Link>
                )}
              </div>
            </nav>
            {/* change  Language  */}
            {/* <div className="language_menu">
              <img src={Globimg} alt="glob" loading="lazy"/>
              <select
                value={language}
                onChange={({ target }) => setLanguage(target.value)}
              >
                <option value="EN">EN</option>
                <option value="HN">HN</option>
              </select>
            </div> */}

            {/*//////////////////// Mobile Menu //////////////////*/}

            <div className="mobile_menu">
              <div className="menu_title">
                <h3>Menu</h3>
                <img
                  src={Menuclaseimg}
                  alt=""
                  onClick={() => setMobileMenu(false)}
                />
              </div>

              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Recommended Courses</Accordion.Header>
                  <Accordion.Body>
                    <MobileCourseMenu menuClose={() => setMobileMenu(false)} />
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>Exam</Accordion.Header>
                  <Accordion.Body>
                    <MobileExamMenu menuClose={() => setMobileMenu(false)} />
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>Our Centers</Accordion.Header>
                  <Accordion.Body>
                    <MobileCenterMenu menuClose={() => setMobileMenu(false)} />
                  </Accordion.Body>
                </Accordion.Item>

                <ul className="mobile_menu_title">
                  {/* <li>
                    <Link to="http://freeresources.shubhraviraj.in/">Free Resources</Link>
                  </li> */}
                  <li>
                    <Link to="https://currentaffairs.shubhraranjan.com/">Current Affairs & Blog</Link>
                  </li>
                  <li>
                    <Link to="/OurStory" onClick={() => setMobileMenu(false)}>
                      Our Story
                    </Link>
                  </li>
                </ul>
              </Accordion>

              {/* <div className="button_wrap fill">
                {cookies ? (
                  <>
                    <Avatar type="home" />
                  </>
                ) : (
                  <Link
                    to="/signup"
                    className="button"
                    onClick={() => setMobileMenu(false)}
                  >
                    Sign In / Sign Up
                  </Link>
                )}
              </div> */}
            </div>
          </div>
        </header>
        <div
          className="mobile_overlay"
          onClick={() => setMobileMenu(false)}
        ></div>
      </div>
    </>
  );
};

export default Header;
