import { decryptData, encryptData } from "config/config";
import { instance as axios } from "config/axiosInstance";
import { getBrowserName, isLoggedIn } from "config/utils";

export const getAllCourses = async (data) => {
  return decryptData(
    await axios.get(
      `/get-website-courses?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
      })}`
    )
  );
};

export const getCourseDetail = async (data) => {
  return decryptData(
    await axios.get(
      `/get-course-details?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
      })}`
    )
  );
};

export const getBillingCourseDetailById = async (data) => {
  return decryptData(
    await axios.get(
      `/purchase-detail?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: isLoggedIn(),
      })}`
    )
  );
};

export const purchaseBillingCourse = async (data) => {
  return decryptData(
    await axios.post(`/purchase`, {
      reqData: encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: isLoggedIn(),
      }),
    })
  );
};

export const GenerateToken = async (data) => {
  return decryptData(
    await axios.post(`/create-token`, {
      reqData: encryptData({
        ...data,
        access_token: isLoggedIn(),
      }),
    })
  );
};

export const getTokenDetails = async (data) => {
  return decryptData(
    await axios.get(
      `/get-details-by-center-id?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
        access_token: isLoggedIn(),
      })}`
    )
  );
};
