import React, { useState } from "react";
import Chart from "react-google-charts";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import {
  Aboutimg1,
  Aboutimg2,
  Aboutimg3,
  Virajimg,
  Shubhraimg,
  Onlineimg,
  ClassRoomImg,
  Regionalimg, EvolvingwithTime, StudentCentricApproach, IntegratedLearning,
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
  gallery9,
} from "assets/images";
import "./OurStory.scss";
import { FaPlay } from "react-icons/fa";
import OurLocation from "components/our-location/OurLocation";
import { getMasterCenters } from "services/master";
import { errorResponse, failResponse, normalEncryptData } from "config/config";

const OurStory = () => {
  const [allCenters, setAllCenters] = useState([]);

  // Slider  //
  var Workshop = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 900,
    autoplay: true,
    autoplaySpeed: 8000,
    centerMode: true,
    centerPadding: "30px",
    responsive: [
      {
        breakpoint: 1367,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
          centerPadding: "0px",
        },
      },
    ],
  };

  // Data Chart//
  const data = [
    ["Year", "Sales", "Expenses", "Profit"],
    ["2014", 1000, 400, 200],
    ["2015", 1170, 460, 250],
    ["2016", 660, 1120, 300],
    ["2017", 1030, 540, 350],
  ];

  const options = {
    chart: {
      title: "Company Performance",
      subtitle: "Sales, Expenses, and Profit: 2014-2017",
    },
  };

  // --------------- getting all required masters data ----------------//
  React.useLayoutEffect(() => {
    const getCentersData = async () => {
      try {
        const res = await getMasterCenters();
        if (res.code === 200) {
          setAllCenters(res.data.centerPage || []);
        } else {
          failResponse(res);
        }
      } catch (err) {
        errorResponse(err);
      }
    };

    // calls all the master functions
    getCentersData();
    // eslint-disable-next-line
  }, []);

  // --------------- getting all home page data ----------------//
  // React.useLayoutEffect(() => {
  //   const getOurStoryData = async () => {
  //     try {
  //       setLoading(true);
  //       const res = await ourStory();
  //
  //       if (res.code === 200) {
  //         setOurToppers(res.data.ourToppers || []);
  //         setFaculties(res.data.faculties.rows || []);
  //         setTestimonials(res.data.testimonial.rows || []);
  //         setLoading(false);
  //       } else {
  //         failResponse(res);
  //         setLoading(false);
  //       }
  //     } catch (err) {
  //       errorResponse(err)
  //       setLoading(false);
  //     }
  //   };

  //   // calls all the master functions
  //   getOurStoryData();

  //   // eslint-disable-next-line
  // }, []);

  // End //
  return (
    <div className="ourStory">
      {/* Banner */}
      <div className="common_banner spacing">
        <div className="container">
          <div className="banner_content">
            <h3>Our Story</h3>
            {/* <p>
              Get a glimpse of our beliefs, our milestones and the people behind
              setting up such a successful institute
            </p> */}
              <p>
              Get a glimpse of our achievements, our beliefs and the people behind Shubhra Ranjan IAS Institute… 
            </p>
          </div>
        </div>
      </div>

      <div className="about_us spacing">
        <div className="container">
          <div className="page_title">
            <h3>About Us</h3>
            {/* <p>Who we are & what our mission is</p> */}
          </div>
          <div className="about_content">
            <div className="image_wrap">
              <img src={Aboutimg1} alt="" loading="lazy" />
              <img src={Aboutimg2} alt="" loading="lazy" />
              <img src={Aboutimg3} alt="" loading="lazy" />
            </div>
            {/* <p>
              Shubhra Viraj EduTech Private Limited is one of the most renowned
              institute to prepare for Civil Services Examinations conducted by
              UPSC. The institute was founded by Shubhra Deshmukh and Viraj
              Deshmukh in 2014. An odd combination of our two founders in terms
              of 20+ years of profound teaching and vast IT experience has today
              resulted in an institute that has a vision to help UPSC aspirants
              from all sections of the society to realize their dream of
              entering Civil Services by providing them up-to-date knowledge
              combined with innovative use of Technology.
            </p> */}
            <p>
            <p style={{textAlign: 'left'}}>
            <b>Shubhra Ranjan IAS </b> is India’s well-known institute to prepare for Civil Services Examinations in India.<br/>
            It was founded with a <b>Vision "To Shape India’s Next Generation Policy Makers and Administrators".</b><br/>
            Since inception our institute has helped more than 62,000 + students to prepare for all three stages of the Civil Services examination: Preliminary, Mains and Personality Test.<br/>
             We offer more than <b> 60 Courses </b> and have a <b> Highly qualified </b> and experienced team of more than <b>30 teachers </b>based in Delhi.<br/>
            We have a <b>Multi-Modal Learning Model</b> with our <b>Main Classroom Campus at Delhi</b>, 15 <b>Regional Centres</b> across India and our <b>Tablet Courses</b> offered <b>Online.</b><br/>
            Our <b>Main Classroom Campus at Delhi</b> has a state of art facilities that include Lecture Halls to conduct lectures, separate Mentoring Rooms for 1:1 mentoring and group mentoring, Study Hall for self-study and for conducting regular test series. We also have in house Cafeteria and a Open Stage where Wajah-Bewajah Lectures are held and is also used for Individual Personality Development.<br/>
            Our Lectures at Delhi centre are relayed LIVE on <b>Tablet</b> and are also available in recorded format on <b>Tablet</b> for later usage. Online Test Series, Mentoring – 1:1 and Group wise are also conducted online on our <b>Tablet</b> which are designed exclusively for our Courses. <br/>
            Lastly our <b>Regional Centres</b> are an extension of our <b>Delhi Centre</b> wherein Offline courses are conducted by our teachers based in Delhi. In other words, our Delhi teachers travel and stay on a given schedule to conduct the offline courses at <b>Regional Centres.</b><br/>
            Over the <b>Last Decade</b>, a <b>Recurring Fact</b> of <b> UPSC CSE </b> Results has been that <b> 8 </b> to <b>10%</b> (<b>70</b> to <b>120</b>) of the <b>Final List</b> every year have been our <b>Regular Classroom Students.</b><br/><br/>
            {/* <b>Included in the list are <b>Tina Dabi AIR 1</b> 2015, <b>Ishita Kishore AIR 1</b> 2022, <b>Anmol Singh Bedi AIR 2</b> 2016, <b>Ankita Agrawal AIR 2</b> 2021, <b>Utkarsh Dwivedi AIR 5</b> 2021, <b>Dinesh Kumar AIR 6</b> 2016, <b>Vishaka Yadav AIR 6</b> 2019, <b>Anand Vardhan AIR 7</b> 2016, <b>Samyak Jain AIR 7</b> 2021, <b>Gunjan Dwivedi AIR 9</b> 2018, <b>Kanika Goyal AIR 9</b> 2022, <b>Satyam Gandhi AIR 10</b> 2020, <b>Harkeerat Singh AIR 10</b> 2021, <b>Ria Dabi AIR 15</b> 2020, <b>Trupti Dhodmise AIR 16</b> 2018, Girish Badole AIR 20 2017, <b>Arpit Chauhan AIR 20</b> 2021  and many thousands more…  </b><br/><br/> */}
            Our Students <b>Always Ahead…  परिणाम बोलते है… </b>
            
            </p>
            <div>
            <br/>
            <div style={{textAlign: 'left', width:"50%", float:"left"}}>
            Shubhra Deshmukh<br/>
            <b>Director</b>
            </div>
            <div style={{textAlign: 'right', float:"right"}}>
            Viraj Deshmukh<br/>
            <b>Director</b>
            </div>

            </div>
            
  

            </p>
            <div className="button_wrap padding">
              {/* <Link to="" className="button">
                Register Today
                <span className="icon">
                  <BsArrowRight />
                </span>
              </Link> */}
            </div>
          </div>
          <div className="result_highlights">
            {/* <div className="chart_wrap padding">
              <h4>YEAR - WISE RESULTS</h4>
              <Chart
                chartType="Bar"
                width="100%"
                height="400px"
                data={data}
                options={options}
              />
            </div> */}
            {/* <div className="highlights padding" style={{marginRight:"1%"}}>
              <h4>2022 YEAR HIGHLIGHTS</h4>
              <ul>
                <li>10 out of 90 students selected in finals</li>
                <li>10 out of 90 students selected in finals</li>
                <li>10 out of 90 students selected in finals</li>
                <li>10 out of 90 students selected in finals</li>
                <li>10 out of 90 students selected in finals</li>
              </ul>
            </div>

            <div className="highlights padding">
              <h4>2023 YEAR HIGHLIGHTS</h4>
              <ul>
                <li>10 out of 90 students selected in finals</li>
                <li>10 out of 90 students selected in finals</li>
                <li>10 out of 90 students selected in finals</li>
                <li>10 out of 90 students selected in finals</li>
                <li>10 out of 90 students selected in finals</li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>

      {/* ////////////////////// grown immensely /////////////////// */}

      <div className="number_data spacing">
        <div className="container">
          <div className="number_data_content">
            <h3>Our institution has grown immensely in the last 10 years</h3>
            <p>
              Together, with our faculty and students we have achieved great
              heights
            </p>
            <ul>
              <li>
                <h3>62,000 + </h3> <span>Students Tutored</span>
              </li>
              <li>
                <h3>60 + </h3> <span>Courses Offered</span>
              </li>
              <li>
                <h3>30 + </h3> <span>Experienced faculties</span>
              </li>
              <li>
                <h3>12 </h3> <span>Regional Centers</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Board Members */}

      {/* <div className="board_members spacing">
        <div className="container">
          <div className="page_title">
            <h3>our board members</h3>
            <p>
              Learn about the key founding members of the institute who have
              laid it’s foundation
            </p>
          </div>

          <div className="board_members_card">
            <div className="board_members">
              <div className="content">
                <h4>SHUBHRA RANJAN </h4>
                <b>DIRECTOR, SHUBHRA RANJAN IAS STUDY PVT. LTD.</b>
                <p>
                  Mrs. Ranjan is the director of Shubhra Ranjan IAS Study Pvt.
                  Ltd. She’s helping a number of students to study and get their
                  names in the coveted final selection list of the top 10
                  ranking students. Her past experience and dedication is what
                  has helped the institute achieve world-class results.
                </p>

              </div>
              <div className="image_wrap">
                <img src={Shubhraimg} alt="" loading="lazy" />
              </div>
            </div>
            <div className="board_members">
              <div className="content">
                <h4>Viraj deshmukh </h4>
                <b>CO - FOUNDER, SHUBHRA RANJAN IAS STUDY PVT. LTD.</b>
                <p>
                  Viraj is the co-founder of Shubhra Viraj Edutech. Prior to
                  that Viraj was a data warehouse architect and an IT services
                  delivery leader at Credit Suisse. Through his experience, he
                  brings to bear a unique blend of financial services knowledge,
                  business partnering, enterprise data warehousing expertise,
                  and project delivery rigour.
                </p>
              </div>
              <div className="image_wrap">
                <img src={Virajimg} alt="" loading="lazy" />
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* ////////////////////// Our Beliefs /////////////////// */}

      <div className="our_beliefs spacing">
        <div className="container">
          <div className="page_title">
            <h3>our beliefs</h3>
            {/* <p>
              What makes us stand out and what beliefs lie at the core of our
              institute?
            </p> */}
            {/* <p style={{maxWidth: '753px'}}>Innovative Learning System: We embrace innovation in teaching methods, study materials, and technology. </p>
            <p style={{maxWidth: '753px'}}>Evolving with time: We have adapted to changing exam patterns, ensuring students receive the most relevant guidance.</p>
            <p style={{maxWidth: '753px'}}>Student-Centric Approach: We focus on building strong student-teacher bonds, personalised feedback, and round-the-clock expert support to enhance the learning experience</p>
            <p style={{maxWidth: '753px'}}>Integrated Learning: We ensure that students get an integrated learning experience without feeling the burden of time and resource paucity.</p> */}

          </div>
          
          <div className="our_beliefs_wrap">
            <div className="our_beliefs_card online">
              <div className="content">
                <h4>Embracing Digital Technology</h4>
                <p>
                  Apart from traditional classroom teaching, we believe that
                  technology has major role to play in reaching out to students
                  & today successfully conducting live classroom programmes
                  across 18 centers.{" "}
                  <img src={Onlineimg} alt="programme" loading="lazy" />{" "}
                </p>
              </div>
            </div>
            <div className="our_beliefs_card classroom">
              <div className="content">
                <h4>Holistic Student Development</h4>
                <p>
                  We do not believe in mere rote learning but our focus is on
                  holistic development of personality of an aspirant which help
                  students fulfil their duties more effectively once they enter
                  the services.{" "}
                  <img src={ClassRoomImg} alt="programme" loading="lazy" />{" "}
                </p>
              </div>
            </div>
            <div className="our_beliefs_card regional">
              <div className="content">
                <h4>Flexibility in Teaching</h4>
                <p>
                  We believe in our unique guidance model wherein aspirants can
                  prepare either at home or offline at any of our centers, hence
                  flexibility & comfort while learning.{" "}
                  <img src={Regionalimg} alt="programme" loading="lazy" />{" "}
                </p>
              </div>
            </div>

            </div>
            <div>&nbsp;</div>
            <div className="our_beliefs_wrap">

            <div className="our_beliefs_card online">
              <div className="content">
                <h4>Evolving with Time</h4>
                <p>
                We have adapted to changing exam patterns, ensuring students receive the most relevant guidance.{" "}
                  <img src={EvolvingwithTime} alt="programme" loading="lazy" />{" "}
                </p>
              </div>
            </div>

            <div className="our_beliefs_card classroom">
              <div className="content">
                <h4> Student-Centric Approach</h4>
                <p>
                We focus on building strong student-teacher bonds, personalised feedback, and round-the-clock expert support to enhance the learning experience.{" "}
                  <img src={StudentCentricApproach} alt="programme" loading="lazy" />{" "}
                </p>
              </div>
            </div>

            <div className="our_beliefs_card regional">
              <div className="content">
                <h4>Integrated Learning</h4>
                <p>
                We ensure that students get an integrated learning experience without feeling the burden of time and resource paucity.{" "}
                  <img src={IntegratedLearning} alt="programme" loading="lazy" />{" "}
                </p>
              </div>
            </div>




          </div>
        </div>
      </div>

      {/* End */}

      {/* Our Faculty */}
      {/* <FacultiesList faculties={faculties} loading={loading} /> */}

      {/* Meet our INSTITUTE Toppers */}
      {/* <InstituteToppers
        title={"Meet our Institute Toppers"}
        toppersArray={ourToppers}
        loading={loading}
      /> */}

      {/* Gallery & Videos */}
      <div className="gallery_videos spacing">
        <div className="container">
          <div className="page_title">
            <h3>Gallery & Videos</h3>
            <p>
              Get a sneak peak into our centers, and what a day at one of our
              locations is like!
            </p>
          </div>

          <div className="slider_wrapper">
            <Slider {...Workshop}>
              <div className="item">
                <div className="image_wrap">
                  <img src={gallery1} alt="" loading="lazy" />
                </div>
                <div className="playicon">
                  <span className="videoplay_icon">
                    <FaPlay />
                  </span>
                </div>
              </div>
              <div className="item">
                <div className="image_wrap">
                  <img src={gallery2} alt="" loading="lazy" />
                </div>
                <div className="playicon">
                  <span className="videoplay_icon">
                    <FaPlay />
                  </span>
                </div>
              </div>
              <div className="item">
                <div className="image_wrap">
                  <img src={gallery3} alt="" loading="lazy" />
                </div>
                <div className="playicon">
                  <span className="videoplay_icon">
                    <FaPlay />
                  </span>
                </div>
              </div>
              <div className="item">
                <div className="image_wrap">
                  <img src={gallery4} alt="" loading="lazy" />
                </div>
                <div className="playicon">
                  <span className="videoplay_icon">
                    <FaPlay />
                  </span>
                </div>
              </div>
              <div className="item">
                <div className="image_wrap">
                  <img src={gallery5} alt="" loading="lazy" />
                </div>
                <div className="playicon">
                  <span className="videoplay_icon">
                    <FaPlay />
                  </span>
                </div>
              </div>
              <div className="item">
                <div className="image_wrap">
                  <img src={gallery6} alt="" loading="lazy" />
                </div>
                <div className="playicon">
                  <span className="videoplay_icon">
                    <FaPlay />
                  </span>
                </div>
              </div>
              <div className="item">
                <div className="image_wrap">
                  <img src={gallery7} alt="" loading="lazy" />
                </div>
                <div className="playicon">
                  <span className="videoplay_icon">
                    <FaPlay />
                  </span>
                </div>
              </div>
              <div className="item">
                <div className="image_wrap">
                  <img src={gallery8} alt="" loading="lazy" />
                </div>
                <div className="playicon">
                  <span className="videoplay_icon">
                    <FaPlay />
                  </span>
                </div>
              </div>
              <div className="item">
                <div className="image_wrap">
                  <img src={gallery9} alt="" loading="lazy" />
                </div>
                <div className="playicon">
                  <span className="videoplay_icon">
                    <FaPlay />
                  </span>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      {/* Our Location */}

      <OurLocation
        // description={
        //   "We have 18 centers across the country where we conduct classes in person for over x no. of students"
        // }
      />

      {/* Regional Centres */}
      {/* hide centers if centers list is unavailable */}
      {allCenters.length > 0 && (
        <div className="regional_cetners spacing">
          <div className="container">
            <div className="page_title padding">
              <h3>Regional Centres</h3>
            </div>

            <ul>
              {allCenters.map(({ center, id }) => {
                return (
                  <li key={id}>
                    <Link
                      to={`/IAS_Institute_`+center?.City.replace(",","")}
                      className="button"
                    >
                      {center?.CenterName || "N/A"}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}

      {/* <Testimonials testimonials={testimonials} loading={loading} /> */}
    </div>
  );
};

export default OurStory;
