import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import { Articleimg, HeaderLogo, GooglePlayStore ,AppleAppStore} from "assets/images/index";
//import { AppleAppStore } from "assert/images";
import { LocationImg, PhoneImg, TwitterLogo } from "assets/icons";
import {
  FaLinkedinIn,
  FaFacebookF,
  FaInstagram,
  FaTelegramPlane,
  FaYoutube,
  FaTimes,
} from "react-icons/fa";
import toast from "react-hot-toast";

const Footer = () => {
  // const onFooter = () => {
  //   toast(
  //     (t) => (
  //       <div className="push_notification">
  //         <h6>TItle</h6>
  //         <p>
  //           Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil in
  //           numquam sequi culpa velit? Neque porro sunt rerum ratione eaque
  //           molestias harum nihil assumenda sed quisquam, esse, dolore sapiente
  //           unde!
  //         </p>
  //         <button onClick={() => toast.dismiss(t.id)}>
  //           <FaTimes />
  //         </button>
  //       </div>
  //     ),
  //     { duration: 90000 }
  //   );
  // };
  return (
    <footer className="footer">
      <div className="footer_top">
        <div className="container">
          <div className="footer_logo">
            <img src={HeaderLogo} alt="Shubra Ranjan" loading="lazy" style={{width:"191px"}}/>
          </div>
          <div className="footer_content">
            <div className="content_left">
              <ul className="text_content">
                <li>
                  <img src={LocationImg} alt="location" loading="lazy" />
                  <p>
                    25, 1st floor, Pusa Road, Near Karol Bagh Metro Station,New
                    Delhi - 110005
                  </p>
                </li>
                <li>
                  <img src={PhoneImg} alt="phone" loading="lazy" />
                  <a href="tel:9810212719">9810212719</a>
                </li>
              </ul>
              <ul>
                <li className="title">Ventures</li>
                <li>
                  <Link to="https://instituteforhumanities.com/index.php/home" target="_blank">IFH ( Institute For Humanities )</Link>
                </li>
              </ul>
              <ul>
                <li className="title">Help</li>
                {/* <li>
                  <Link to="">Contact Us</Link>
                </li> */}
                <li>
                  <Link to="/faqs">FAQs</Link>
                </li>
              </ul>
              <ul>
                <li className="title">Company</li>
                <li>
                  <Link to="/terms-and-condition">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </div>

            {/* Subscribe to the Newsletter */}
            <div className="content_right">
              {/* <h5>Subscribe to the Newsletter</h5>
              <p>
                to get daily updates on current affairs and free materials,
                directly into your inbox
              </p>
              <div className="footer_form">
                <form>
                  <input type="email" placeholder="Your email address..." />
                  <button type="button" className="button">
                    Submit
                  </button>
                </form>
              </div> */}
              <div className="social_icons">
                <h6>Social Connects</h6>
                <ul>
                  <li>
                    <button
                      onClick={() =>
                        window.open("https://www.facebook.com/shubhraranjanias")
                      }
                      className="facebook"
                    >
                      <FaFacebookF />
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() =>
                        window.open("https://www.youtube.com/@SHUBHRARANJAN")
                      }
                      className="youtube"
                    >
                      <FaYoutube />
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() =>
                        window.open(
                          "https://in.linkedin.com/company/shubhra-viraj-edutech?trk=similar-pages"
                        )
                      }
                      className="linkedin"
                    >
                      <FaLinkedinIn />
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() =>
                        window.open("https://twitter.com/shubhraranjan")
                      }
                      className="twitter"
                    >
                      {/* <FaTwitter /> */}
                      <img src={TwitterLogo} alt="" loading="lazy" />
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() =>
                        window.open(
                          "https://www.instagram.com/shubhraranjanias/?igshid=6h52c5f71k4d"
                        )
                      }
                      className="instagram"
                    >
                      <FaInstagram />
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => window.open("https://t.me/sriasofficial")}
                      className="telegram"
                    >
                      <FaTelegramPlane />
                    </button>
                  </li>
                </ul>
                <br></br>
                <div>
                <h6>Download Our App</h6>
                </div>
                <div style={{display:"flex"}}>
                <div>
                  <img
                    src={GooglePlayStore}
                    alt="Google Play Store"
                    loading="lazy"
                    className="GooglePlayStore"
                    onClick={() => window.open("https://play.google.com/store/apps/details?id=com.shubhraranjanmobile")}
                  />
                </div>
                &nbsp;&nbsp;&nbsp;
                <div>
                  <img
                    src={AppleAppStore}
                    alt="Google Play Store"
                    loading="lazy"
                    className="GooglePlayStore"
                    onClick={() => window.open("https://apps.apple.com/us/app/shubhra-ranjan/id6474115818")}
                  />
                </div>
                </div>
                
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
      <div className="footer_bottom">
        <div className="container">
          <ul>
            <li>
              <Link to="/privacy-policy">Our Privacy Policy</Link>
            </li>
            <li>©. Copyright Shubhra Ranjan IAS Study Pvt. Ltd. © 2024. All Rights Reserved Site</li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
