import React, { useState } from "react";
import "./DevelopmentVideoSlider.scss";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
// import FancyVideo from "react-videojs-fancybox";
import { FaPlay } from "react-icons/fa";
import Slider from "react-slick";
import VideoPlayModal from "components/video-play-modal/VideoPlayModal";

const DevelopmentVideoSlider = ({ sliderItem }) => {
  const [modalSHow, setModalShow] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");

  const handleShow = () => {
    setModalShow(true);
  };
  const handleClose = () => {
    setModalShow(false);
  };
  var DevelopmentSlider = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 900,
    autoplay: true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <section className="development_slider spacing">
      <div className="container">
        <Slider {...DevelopmentSlider}>
          {sliderItem &&
            sliderItem.map((item, index) => {
              return (
                <div className="item" key={index}>
                  <div className="development_slider_card">
                    <div className="content">
                      <h6>{item.question}</h6>
                      <h3>{item.title}</h3>
                      <p>{item.description?.slice(0, 350)}</p>
                      {item.url && (
                        <div className="button_wrap fill padding">
                          <Link to={`${item.url}`} className="button">
                            {/* {item.url} */}
                            Register Now
                            <span className="icon">
                              <BsArrowRight />
                            </span>
                          </Link>
                        </div>
                      )}
                    </div>
                    <div className="image_wrap">
                      <p
                        className="playicon"
                        onClick={() => {
                          setSelectedVideo(item.video);
                          handleShow();
                        }}
                      >
                        <span className="videoplay_icon">
                          <FaPlay />
                        </span>
                      </p>

                      {/* <FancyVideo source={item.video} poster={item.image}  /> */}
                      <img src={item.image} alt="" loading="lazy" />
                    </div>
                  </div>
                </div>
              );
            })}
        </Slider>
        <VideoPlayModal
          modalShow={modalSHow}
          modalHide={handleClose}
          video={selectedVideo}
        />
      </div>
    </section>
  );
};

export default DevelopmentVideoSlider;
